import { DocumentBase } from 'api/document-base.model';
import { TrackerStatus } from 'pages/Scheduling/components/TrackerStatusDropdown';
import LookupBase from './lookup-base.model';
import IPatientAppointment from './Scheduling/patientAppointment.model';

export enum EncounterStatus {
    Created = 'Created',
    ReadyForAttestation = 'ReadyForAttestation',
    Attested = 'Attested',
    AmendRequired = 'AmendRequired',
    Completed = 'Completed',
    Billed = 'Billed',

    ConversionImported = 'ConversionImported',

    CorrectionAmend = 'CorrectionAmend',
    CorrectionsNeeded = 'CorrectionsNeeded',
    CorrectionsCompleted = 'CorrectionsCompleted',
    ReBillOnHold = 'ReBillOnHold',
}

export const encounterStatusText: { [key: string]: string } = {
    [EncounterStatus.Created]: 'In Progress',
    [EncounterStatus.ReadyForAttestation]: 'Ready for Attestation',
    [EncounterStatus.Attested]: 'Attested',
    [EncounterStatus.AmendRequired]: 'Amend Required',
    [EncounterStatus.Completed]: 'Completed',
    [EncounterStatus.Billed]: 'Approved',
    [EncounterStatus.ConversionImported]: 'Conversion Imported',
    [EncounterStatus.CorrectionAmend]: 'Correction Addendum',
    [EncounterStatus.CorrectionsNeeded]: 'Corrections Needed',
    [EncounterStatus.CorrectionsCompleted]: 'Corrections Completed',
    [EncounterStatus.ReBillOnHold]: 'ReApprove on Hold',
};

export interface IPatientEncounter extends DocumentBase {
    patientId?: string;
    status?: EncounterStatus;
    billingStatus?: string;

    isProblemsReviewed: boolean;
    problemsReviewedBy?: string;
    problemsReviewedDate?: string;

    isAllergiesReviewed: boolean;
    allergiesReviewedBy?: string;
    allergiesReviewedDate?: string;

    isMedicationsReviewed: boolean;
    medicationsReviewedBy?: string;
    medicationsReviewedDate?: string;

    encounterReason?: string;
    encounterNumber?: string;
    encounterHistory?: IEncounterReasonsHistory[];
    locationOfCareId: string;
    encounterDate?: string;
    statusChangeDate?: string;

    attestingProviderId?: string;
    treatingProviderId?: string;
    supervisingProviderId?: string;
    billingProviderId?: string;
    hygienistId?: string;

    references?: Record<string, string>;

    readonly isOnAdministrativeHold: boolean;
    administrativeHold?: {
        note?: string;
        createdBy?: string;
        createdOn?: string;
    };
    billingNote?: string;
}

export type IPatientEncounterWithAppointment = {
    encounter?: IPatientEncounter;
    appointment?: IPatientAppointment;
};

export interface IEncounterHistory {
    patientId?: string;
    encounterId?: string;
    encounterNumber?: string;
    providerId?: string;
    hygienistId?: string;
    appointmentId?: string;
    date?: string;
    appointmentStartTime?: string;
    procedureCodes?: string;
    encounterReason?: string;
    status?: EncounterStatus;
    trackerStatus?: TrackerStatus;
    cancellationReason?: string;
    cancellationReasonId?: string;
    cancellationUserId?: string;
    cancellationNote?: string;
    modifiedBy?: string;
    modifiedOn: string;
    locationOfCareId?: string;
    locationOfCareName?: string;
    isDeleted: boolean;
}

export interface IEncounterReason extends LookupBase {
    departmentType: string;
    references?: Record<string, string>;
}
export interface IEncounterReasonsHistory {
    date?: Date;
    encounterReason?: string;
    encounterId: string;
}

export interface IEncounterStatus extends LookupBase {
    description?: string;
    code?: string;
}

export type EncounterHoldStatusPayload = {
    hold: boolean;
    note: string;
};
