import { Stack, Text } from '@fluentui/react';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { useSelector } from 'hooks';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { selectWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { classicDateOnly } from 'utils/dateOnly';

export default function VisitSummaryFinancialInformation(): JSX.Element {
    const selectedReadyToReviewEncounter = useSelector(selectWorkListEditItem<IWorkListEncounterView>);

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <PanelSectionHeader text="Financial Information" />
            <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 10 }}>
                {selectedReadyToReviewEncounter?.primaryPayerName ? (
                    <Stack tokens={{ childrenGap: 5 }}>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <Stack.Item>
                                <Text>Primary Payer: </Text>
                                <Text>
                                    <strong>
                                        {selectedReadyToReviewEncounter?.primaryPayerName
                                            ? selectedReadyToReviewEncounter?.primaryPayerName
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text>Primary Last Verified: </Text>
                                <Text>
                                    <strong>
                                        {selectedReadyToReviewEncounter?.primaryLastVerified
                                            ? classicDateOnly(selectedReadyToReviewEncounter?.primaryLastVerified)
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack.Item>
                        </Stack>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <Stack.Item>
                                <Text>Secondary Payer: </Text>
                                <Text>
                                    <strong>
                                        {selectedReadyToReviewEncounter?.secondaryPayerName
                                            ? selectedReadyToReviewEncounter?.secondaryPayerName
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text>Secondary Last Verified: </Text>
                                <Text>
                                    <strong>
                                        {selectedReadyToReviewEncounter?.secondaryLastVerified
                                            ? classicDateOnly(selectedReadyToReviewEncounter?.secondaryLastVerified)
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                ) : null}
                {selectedReadyToReviewEncounter?.selfPay ? (
                    <Text>
                        <strong>Self Pay </strong>
                    </Text>
                ) : null}
                {selectedReadyToReviewEncounter?.slidingFeeProgram ? (
                    <Stack tokens={{ childrenGap: 5 }}>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <Stack.Item>
                                <Text>Sliding Fee Program: </Text>
                                <Text>
                                    <strong>
                                        {selectedReadyToReviewEncounter?.slidingFeeProgram
                                            ? selectedReadyToReviewEncounter?.slidingFeeProgram
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack.Item>

                            <Stack.Item>
                                <Text>Sliding Fee Effective Date: </Text>
                                <Text>
                                    <strong>
                                        {selectedReadyToReviewEncounter?.slidingFeeEffective
                                            ? classicDateOnly(selectedReadyToReviewEncounter?.slidingFeeEffective)
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text>Sliding Fee Expiration Date: </Text>
                                <Text>
                                    <strong>
                                        {selectedReadyToReviewEncounter?.slidingFeeEnd
                                            ? classicDateOnly(selectedReadyToReviewEncounter?.slidingFeeEnd)
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack.Item>
                        </Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                <Text>Sliding Fee Plan: </Text>
                                <Text>
                                    <strong>
                                        {selectedReadyToReviewEncounter?.slidingFeePlan
                                            ? selectedReadyToReviewEncounter?.slidingFeePlan
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                ) : null}
            </Stack>
        </Stack>
    );
}
