import { map } from 'lodash';
import { RootState } from 'state/store';

export default function RDA({ state }: { state: RootState }): JSX.Element | null {
    const rdaId = state.encounter.patientEncounterAppointment?.registeredDentalAssistantId;
    const rdas = map(state.tenant.providers.data, (provider) => provider).filter(
        (provider) => provider?.isRegisteredDentalAssistant,
    );
    const rda = rdas.find((provider) => provider?.id === rdaId);
    if (!rdaId) return null;
    return (
        <>
            <h3>Registered Dental Assistant</h3>
            <ul>
                <li>
                    {rda?.firstName} {rda?.lastName}
                    {rda?.suffix ? `, ${rda.suffix}` : ''}
                </li>
            </ul>
        </>
    );
}
