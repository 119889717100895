import { map } from 'lodash';
import { RootState } from 'state/store';

export default function Hygienist({ state }: { state: RootState }): JSX.Element | null {
    const hygienistId = state.encounter.patientEncounterAppointment?.hygienistId;
    const hygienists = map(state.tenant.providers.data, (p) => p).filter((provider) => provider?.isHygienist);
    const hygienist = hygienists.find((provider) => provider?.id === hygienistId);
    if (!hygienistId) return null;
    return (
        <>
            <h3>Hygienist</h3>
            <ul>
                <li>
                    {hygienist?.firstName} {hygienist?.lastName}
                    {hygienist?.suffix ? `, ${hygienist.suffix}` : ''}
                </li>
            </ul>
        </>
    );
}
