import { Dictionary } from '@reduxjs/toolkit';
import { AxiosRequestConfig, CancelToken } from 'axios';
import queryString from 'query-string';
import { TaskTargets, TaskType } from 'state/task-management/taskManagement.actions';
import ApiClient from './apiClient';
import IPatientAppointment from './models/Scheduling/patientAppointment.model';
import IServiceUserAccount, { IUserIdentity } from './models/account.model';
import { BenefitPlans, IBenefitPlan } from './models/benefit-plan.model';
import { IBillingProcedure } from './models/billing-procedure.model';
import { IChartDentition } from './models/chart-dentition.model';
import IChartNote from './models/chart-note.model';
import IChart, { ChartEntities, IChartCondition, IChartProcedure, TenantEntities } from './models/chart.model';
import { ReadyForAttestationEncounterView } from './models/clinical-huddle';
import { IDiagnosis } from './models/diagnosis.model';
import {
    EncounterHoldStatusPayload,
    IEncounterHistory,
    IEncounterReason,
    IEncounterStatus,
    IPatientEncounter,
    IPatientEncounterWithAppointment,
} from './models/encounter.model';
import IForm, { FormName } from './models/form';
import ILanguage from './models/languages.model';
import { DentalLookupEntities, ICondition } from './models/lookup.model';
import IMedication from './models/medications.model';
import IPatientAllergies, { IAllergen, IAllergyReaction } from './models/patient-allergy.model';
import IPatientCommunication from './models/patient-communication.model';
import IPatientContact from './models/patient-contact.model';
import IPatientImmunizations from './models/patient-immunization.model';
import IPatientMedicationsView, { IMedicationStopReason } from './models/patient-medication.model';
import IPatientPrescription from './models/patient-prescription';
import IPatientProblems, { IProblem } from './models/patient-problem.model';
import IPatientVitals from './models/patient-vital.model';
import IPatient, { IAnnualIncome, IPatientAudit, IPatientImagingModel, IPovertyPercentage } from './models/patient.model';
import { IPayerDetail } from './models/payer-detail.model';
import { IPayer, ITenantPayer } from './models/payer.model';
import { IPerioExam } from './models/perio-exam.model';
import IPrescriptionItem from './models/prescription.model';
import { IProcedureCategories } from './models/procedure-category.model';
import { IProcedure } from './models/procedure.model';
import IProvider from './models/provider.model';
import { IQuickActionCategories, IQuickActionCategory } from './models/quick-action-category-model';
import { IQuickActions } from './models/quick-action.model';
import IQuickNoteTemplate from './models/quick-note-template.model';
import ISlidingFeeProgram, { ISlidingFeePlan } from './models/sliding-fee-program.model';
import ITenantSubscription from './models/tenant-subscription.model';
import ITenant, { ITenantUser } from './models/tenant.model';
import {
    IChartTreatmentPlan,
    IChartTreatmentPlanPhase,
    IChartTreatmentPlanPhaseProcedure,
    IPendingChartTreatmentPlanPhaseProcedure,
} from './models/treatment-plan.model';
import IUserTask from './models/user-task.model';

import { ITag } from '@fluentui/react';
import { IAmendmentNote } from 'pages/Landing/AdminHuddle/components/LayerComponents/VisitSummary/VisitSummaryPanel';
import { WorkListAppointmentUpdateParams } from 'state/slices/admin-huddle/worklists/appointment-worklists/appointment-worklists.actions';
import { WorkListEncounterUpdateParams } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import { GetWorkListDataByWorkListParams } from 'state/slices/admin-huddle/worklists/worklist.actions';
import { WorkListDetail } from 'state/slices/admin-huddle/worklists/worklist.state';
import { OverviewItem } from 'state/slices/patient/patient.state';
import { ReportQuery } from 'state/slices/reporting/reporting.actions';
import { IDosageFrequency } from 'state/slices/tenant/dosageFrequency';
import { IDosageQuantity } from 'state/slices/tenant/dosageQuantity';
import { FeatureFlag } from 'state/slices/tenant/feature-flags.slice';
import { IGenderIdentity } from 'state/slices/tenant/gender-identities.slice';
import { ILegalSex } from 'state/slices/tenant/legal-sexes.slice';
import { Race } from 'state/slices/tenant/races.slice';
import { DentalApiConfig } from './api.config';
import { IPatientAdjustmentHistoryView } from './models/adjustment-history.model';
import IAdjustmentReason from './models/adjustment-reason.model';
import { IAppointmentCheckoutModel } from './models/appointment-checkout.model';
import { IAppointmentHistory } from './models/appointment-history.model';
import { IBatch } from './models/batch.model';
import ICarcRarcsDetail from './models/carc-rarc-detail.model';
import { ClinicalAlertTypeLookup, IClinicalAlert, IClinicalAlerts } from './models/clinical-alert.model';
import { IDocument } from './models/document.model';
import { IEmbedReportModel, IReport, ReportType } from './models/embed-report.model';
import IEncounterClaimSummary, { IEncounterCorrespondence } from './models/encounter-claim-summary.model';
import {
    EncounterSummaryWithLineItemsView,
    ICommonTransaction,
    IEncounterLedger,
    ILedgerTransactionSummaryView,
} from './models/encounter-ledger.model';
import IImagingSource, { IImagingSourceGeneric } from './models/imaging-source.model';
import LookupBase, { LookupCodeBase } from './models/lookup-base.model';
import { IMostRecentTreatmentPlanView } from './models/most-recent-treatment-plan-view.model';
import { INegativeReversal } from './models/negative-reversal-reason.model';
import { IPatientLedger, ITransaction } from './models/patient-ledger.model';
import { IPatientPaymentHistoryView } from './models/payment-history-model';
import { IPaymentSource } from './models/payment-source.model';
import IPaymentSourceReversal from './models/paymentSourceReversal.model';
import IReportingGroup, { IReportingCategory, IReportingType } from './models/reporting-group.model';
import { TaskGroup } from './models/user-tasks-by-group';
import IWorkListAppointmentView from './models/worklist-appointment-view';
import IWorkListEncounterView from './models/worklist-encounter-view';
import IProcedureCreditTransaction from './models/procedure-credit-transaction';
import IConverOveragePayload from './models/unapply-patient-payments.model';
import DashboardBoardTotals from './models/dashboard-totals.model';
import IHoldingStatement from './models/holding-statement.model';
import IStatementConfig from './models/statement-config.model';
import IPatientEncounterWithTasks from './models/patient-encounter-with-tasks.model';

class DentalApi extends ApiClient {
    constructor() {
        super(DentalApiConfig);
    }

    // Account =====================================
    getAccount() {
        return this.client.get<IServiceUserAccount>(`Account`);
    }
    getAccountById(tenantId: string, accountId: string, config?: AxiosRequestConfig) {
        return this.client.get<IUserIdentity>(`Account/Tenants/${tenantId}/Users/${accountId}`, config);
    }
    getAccountsByIds(tenantId: string, payload: { userIds: string[] }, config?: AxiosRequestConfig) {
        return this.client.post<IUserIdentity[]>(`Account/Tenants/${tenantId}/Users`, payload, config);
    }
    // Allergies =====================================
    getPatientAllergies(tenantId: string, patientId: string) {
        return this.client.get<IPatientAllergies>(`Tenants/${tenantId}/Patients/${patientId}/Allergies`);
    }
    updatePatientAllergies(tenantId: string, patientId: string, allergies: IPatientAllergies) {
        return this.client.put<IPatientAllergies>(`Tenants/${tenantId}/Patients/${patientId}/Allergies`, allergies);
    }
    createPatientAllergies(tenantId: string, patientId: string, allergies: IPatientAllergies) {
        return this.client.post<IPatientAllergies>(`Tenants/${tenantId}/Patients/${patientId}/Allergies`, allergies);
    }

    // Allergens =====================================
    getAllAllergens() {
        return this.client.get<Dictionary<IAllergen>>(`Allergens`);
    }
    searchAllergens(query: string) {
        return this.client.post<Dictionary<IAllergen>>(`Allergens/Search?query=${query}`);
    }
    // Allergy Reactions =====================================
    getAllAllergyReactions() {
        return this.client.get<Dictionary<IAllergyReaction>>(`AllergyReactions`);
    }

    // Problems =====================================
    getPatientProblems(tenantId: string, patientId: string) {
        return this.client.get<IPatientProblems>(`Tenants/${tenantId}/Patients/${patientId}/Problems`);
    }
    getHealthHistoryProblems() {
        return this.client.get<Dictionary<IProblem>>(`Problems/HealthHistory`);
    }
    getProblemById(id: string) {
        return this.client.get<IProblem>(`Problems/${id}`);
    }
    updatePatientProblems(tenantId: string, patientId: string, model: IPatientProblems) {
        return this.client.put<IPatientProblems>(`Tenants/${tenantId}/Patients/${patientId}/Problems`, model);
    }

    // Medications =====================================
    getPatientMedications(tenantId: string, patientId: string) {
        return this.client.get<IPatientMedicationsView>(`Tenants/${tenantId}/Patients/${patientId}/Medications`);
    }
    updatePatientMedications(tenantId: string, patientId: string, model: IPatientMedicationsView) {
        return this.client.put<IPatientMedicationsView>(`Tenants/${tenantId}/Patients/${patientId}/Medications`, model);
    }
    createPatientMedications(tenantId: string, patientId: string, model: IPatientMedicationsView) {
        return this.client.post<IPatientMedicationsView>(`Tenants/${tenantId}/Patients/${patientId}/Medications`, model);
    }

    // Medications Stop Reasons =========================
    getMedicationStopReasons() {
        return this.client.get<Dictionary<IMedicationStopReason>>(`MedicationStopReasons`);
    }
    // Vitals =====================================
    getPatientVitals(tenantId: string, patientId: string) {
        return this.client.get<IPatientVitals>(`Tenants/${tenantId}/Patients/${patientId}/Vitals`);
    }
    updatePatientVitals(tenantId: string, patientId: string, model: IPatientVitals, encounterId: string) {
        return this.client.put<IPatientVitals>(
            `Tenants/${tenantId}/Patients/${patientId}/Vitals/Encounter/${encounterId}`,
            model,
        );
    }
    createPatientVitals(tenantId: string, patientId: string, model: IPatientVitals, encounterId: string) {
        return this.client.post<IPatientVitals>(
            `Tenants/${tenantId}/Patients/${patientId}/Vitals/Encounter/${encounterId}`,
            model,
        );
    }

    // Immunizations =====================================
    getPatientImmunizations(tenantId: string, patientId: string) {
        return this.client.get<IPatientImmunizations>(`Tenants/${tenantId}/Patients/${patientId}/Immunizations`);
    }

    updatePatientImmunizations(tenantId: string, patientId: string, immunization: IPatientImmunizations) {
        return this.client.put<IPatientImmunizations>(`Tenants/${tenantId}/Patients/${patientId}/Immunizations`, immunization);
    }
    createPatientImmunizations(tenantId: string, patientId: string, immunization: IPatientImmunizations) {
        return this.client.post<IPatientImmunizations>(`Tenants/${tenantId}/Patients/${patientId}/Immunizations`, immunization);
    }

    // Patient Overview =====================================
    createOverviewItem(tenantId: string, patientId: string, item: OverviewItem, type: string) {
        return this.client.post<OverviewItem>(`Tenants/${tenantId}/Patients/${patientId}/${type}`, item);
    }
    updateOverviewItem(tenantId: string, patientId: string, item: OverviewItem, type: string) {
        return this.client.put<OverviewItem>(`Tenants/${tenantId}/Patients/${patientId}/${type}`, item);
    }

    // Patient Contacts
    getPatientContact(tenantId: string, parentId: string, contactId: string) {
        return this.client.get<IPatientContact>(`Tenants/${tenantId}/PatientContacts?id=${contactId}&parentId=${parentId}`);
    }
    addPatientContact(tenantId: string, parentId: string, model: IPatientContact) {
        return this.client.post<IPatientContact>(`Tenants/${tenantId}/PatientContacts?parentId=${parentId}`, model);
    }
    updatePatientContact(tenantId: string, parentId: string, model: IPatientContact) {
        return this.client.put<IPatientContact>(`Tenants/${tenantId}/PatientContacts?parentId=${parentId}`, model);
    }
    getAllPatientContacts(tenantId: string, parentId: string) {
        return this.client.get<IPatientContact[]>(`Tenants/${tenantId}/PatientContacts/All?parentId=${parentId}`);
    }
    /**
     * @description Returns a list of patient results inside a tenant based on the phone search using equals logic.
     */
    getPatientContacts(tenantId: string, query: string) {
        return this.client.get<IPatientContact[]>(`Tenants/${tenantId}/PatientContacts/PhoneSearch?query=${query}`);
    }

    // Patient Communication
    getAllPatientCommunication(tenantId: string, patientId: string) {
        return this.client.get<IPatientCommunication[]>(`Tenants/${tenantId}/Patients/${patientId}/Communication`);
    }
    getPatientCommunicationById(tenantId: string, patientId: string, patientCommunicationId: string) {
        return this.client.get<IPatientCommunication>(
            `Tenants/${tenantId}/Patients/${patientId}/Communication/${patientCommunicationId}`,
        );
    }
    getPatientCommunicationByEncounterId(tenantId: string, patientId: string, encounterId: string) {
        return this.client.get<IPatientCommunication>(`Tenants/${tenantId}/Patients/${patientId}/Communication/${encounterId}`);
    }
    addPatientCommunication(tenantId: string, patientId: string, entity: IPatientCommunication) {
        return this.client.post<IPatientCommunication>(`Tenants/${tenantId}/Patients/${patientId}/Communication`, entity);
    }
    updatePatientCommunication(tenantId: string, patientId: string, entity: IPatientCommunication) {
        return this.client.put<IPatientCommunication>(`Tenants/${tenantId}/Patients/${patientId}/Communication`, entity);
    }

    // Patient =====================================
    /**
     * @description Returns a list of patients results inside a tenant based on the search parameters.
     */
    getPatients(tenantId: string, query: string) {
        // if query converted isNumber it should be mrn
        return this.client.get<IPatient[]>(`Tenants/${tenantId}/Patients/Search?${query}`);
    }
    getPatient(tenantId: string, patientId: string) {
        return this.client.get<IPatient>(`Tenants/${tenantId}/Patients/${patientId}`);
    }
    addPatient(tenantId: string, model: IPatient) {
        return this.client.post<IPatient>(`Tenants/${tenantId}/Patients`, model);
    }
    updatePatient(tenantId: string, model: IPatient) {
        return this.client.put<IPatient>(`Tenants/${tenantId}/Patients`, model);
    }
    //Apply/Unapply payments =============================
    unapplyPatientPayments(tenantId: string, payload: IConverOveragePayload) {
        return this.client.put<ITransaction[]>(`Tenants/${tenantId}/UnapplyPatientPayments`, payload);
    }
    unapplyPatientCredit(tenantId: string, payload: IProcedureCreditTransaction) {
        return this.client.put<ITransaction[]>(`Tenants/${tenantId}/UnapplyPatientCredit`, payload);
    }

    // Patient Ledger =====================================
    getPatientLedgerTransaction(tenantId: string, patientId: string, transactionId: string, cancelToken?: CancelToken) {
        return this.client.get<ITransaction>(`Tenants/${tenantId}/Patients/${patientId}/LedgerTransaction/${transactionId}`, {
            cancelToken,
        });
    }
    getPatientLedger(tenantId: string, patientId: string) {
        return this.client.get<IPatientLedger>(`Tenants/${tenantId}/Patients/${patientId}/Ledger`);
    }
    createPatientLedger(tenantId: string, model: IPatientLedger) {
        return this.client.post<IPatientLedger>(`Tenants/${tenantId}/Patients/Ledger`, model);
    }
    updatePatientLedger(tenantId: string, model: IPatientLedger) {
        return this.client.put<IPatientLedger>(`Tenants/${tenantId}/Patients/Ledger`, model);
    }

    // Patient Encounter Ledger =====================================
    getLedgerTotalsView(tenantId: string, patientId: string, cancelToken?: CancelToken) {
        return this.client.get(`Tenants/${tenantId}/Patients/${patientId}/LedgerTotalsView`, { cancelToken });
    }
    getEncounterLedgers(tenantId: string, patientId: string) {
        return this.client.get<IEncounterLedger[]>(`Tenants/${tenantId}/Ledger/${patientId}`);
    }
    getEncounterLedger(tenantId: string, patientId: string, encounterId: string) {
        return this.client.get<IEncounterLedger>(`Tenants/${tenantId}/Ledger/${patientId}/${encounterId}`);
    }
    createLedgerTransaction(tenantId: string, transactions: ICommonTransaction[]) {
        return this.client.post<ICommonTransaction[]>(`Tenants/${tenantId}/LedgerTransaction`, transactions);
    }
    createAppliedFromUACLedgerTransactions(tenantId: string, uacTransactionId: string, transactions: ICommonTransaction[]) {
        return this.client.post<ICommonTransaction[]>(`Tenants/${tenantId}/LedgerTransaction/${uacTransactionId}`, transactions);
    }
    getEncounterLedgerTransactionSummary(tenantId: string, encounterId: string, cancelToken?: CancelToken) {
        return this.client.get<ILedgerTransactionSummaryView>(
            `Tenants/${tenantId}/Encounters/${encounterId}/LedgerTransactionSummaryView`,
            { cancelToken },
        );
    }
    getPatientAdjustmentHistory(tenantId: string, patientId: string, cancelToken?: CancelToken) {
        return this.client.get<IPatientAdjustmentHistoryView>(`Tenants/${tenantId}/PatientAdjustmentHistoryView/${patientId}`, {
            cancelToken,
        });
    }
    //Payment History
    getPatientPaymentHistory(tenantId: string, patientId: string, cancelToken?: CancelToken) {
        return this.client.get<IPatientPaymentHistoryView>(`Tenants/${tenantId}/PatientPaymentHistoryView/${patientId}`, {
            cancelToken,
        });
    }
    getPatientUnappliedPayments(tenantId: string, patientId: string, cancelToken?: CancelToken) {
        return this.client.get<IPatientPaymentHistoryView>(`Tenants/${tenantId}/PatientUnappliedPaymentsView/${patientId}`, {
            cancelToken,
        });
    }
    getProcedureCreditTransactions(tenantId: string, patientId: string, cancelToken?: CancelToken) {
        return this.client.get<IProcedureCreditTransaction[]>(
            `Tenants/${tenantId}/Patients/${patientId}/ProcedureCreditTransactions`,
            { cancelToken },
        );
    }
    reversePatientPaymentSource(tenantId: string, reversalPaymentSource: IPaymentSourceReversal) {
        return this.client.post<IPatientPaymentHistoryView[]>(
            `Tenants/${tenantId}/PaymentSource/Reversal`,
            reversalPaymentSource,
        );
    }
    getNegativePaymentReasons(tenantId: string) {
        return this.client.get<Dictionary<INegativeReversal>>(`NegativePaymentReasons?tenantId=${tenantId}`);
    }

    getPatientEncounterSummariesWithLineItemsView(tenantId: string, patientId: string, cancelToken?: CancelToken) {
        return this.client.get<EncounterSummaryWithLineItemsView>(
            `Tenants/${tenantId}/Patients/${patientId}/EncounterSummariesWithLineItemsView`,
            { cancelToken },
        );
    }
    rebillEncounterById(tenantId: string, encounterId: string, note: string, sendToBilling?: true, sendToSource?: false) {
        const query = queryString.stringify({ sendToBilling, sendToSource });
        return this.client.put(`Tenants/${tenantId}/Encounters/${encounterId}/Rebill?${query}`, { note });
    }
    //Adjustments
    getAllAdjustmentReasons() {
        return this.client.get<Dictionary<IAdjustmentReason>>('AdjustmentReasons');
    }

    //Payment Source
    getPaymentSources(tenantId: string) {
        return this.client.get<IPaymentSource[]>(`Tenants/${tenantId}/PaymentSource`);
    }
    createPaymentSource(tenantId: string, paymentSource: IPaymentSource) {
        return this.client.post<IPaymentSource>(`Tenants/${tenantId}/PaymentSource`, paymentSource);
    }
    updatePaymentSource(tenantId: string, paymentSource: IPaymentSource) {
        return this.client.put<IPaymentSource>(`Tenants/${tenantId}/PaymentSource`, paymentSource);
    }
    getPaymentSourceById(tenantId: string, paymentSourceId: string, cancelToken?: CancelToken) {
        return this.client.get<IPaymentSource>(`Tenants/${tenantId}/PaymentSource/${paymentSourceId}`, { cancelToken });
    }
    // Patient Audit Logging =====================================
    getPatientAuditLogging(tenantId: string, patientId: string) {
        return this.client.get<IPatientAudit>(`Tenants/${tenantId}/Patients/${patientId}/AuditLog`);
    }
    addPatientAuditLogging(tenantId: string, patientId: string) {
        return this.client.post<IPatientAudit>(`Tenants/${tenantId}/Patients/${patientId}/AuditLog`);
    }
    updatePatientAuditLogging(tenantId: string, patientId: string) {
        return this.client.put<IPatientAudit>(`Tenants/${tenantId}/Patients/${patientId}/AuditLog`);
    }
    //Claim History
    getClaimStatusSummary(tenantId: string, encounterId: string, cancelToken?: CancelToken) {
        return this.client.get<IEncounterClaimSummary>(`Tenants/${tenantId}/Encounter/${encounterId}/ClaimStatusSummary`, {
            cancelToken,
        });
    }

    // Documents =====================================

    public getPatientDocuments(tenantId: string, patientId: string) {
        return this.client.get<IDocument[]>(`Tenants/${tenantId}/Documents/Patient/${patientId}`);
    }
    public getDocumentsType() {
        return this.client.get(`DocumentTypes`);
    }
    public createDocument(tenantId: string, document: IDocument) {
        return this.client.post(`Tenants/${tenantId}/Documents`, document);
    }
    public updateDocument(tenantId: string, document: IDocument) {
        return this.client.put<IDocument>(`Tenants/${tenantId}/Documents`, document);
    }
    public getTenantDocumentsLink(tenantId: string, fileId: string) {
        return this.client.get(`Tenants/${tenantId}/Documents/${fileId}/Link`);
    }
    public getDocumentsLink(documentId: string) {
        return this.client.get(`Documents/${documentId}/Link`);
    }
    public getImagingBridgeLink() {
        return this.client.get<string>(`Documents/ImagingBridge`);
    }
    public getNonTenantDocumentsLink() {
        return this.client.get<IDocument[]>(`Documents`);
    }
    public uploadDocuments(tenantId: string, fileId: string, binaryData: FormData) {
        const config: AxiosRequestConfig = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return this.client.post(`Tenants/${tenantId}/Documents/${fileId}/Upload`, binaryData, config);
    }

    // PovertyGuidelines =====================================
    /**
     * @description Calculates poverty percent bases on poverty guideline in the tenant.
     */
    getPovertyPercentage(model: IPovertyPercentage, id: string) {
        return this.client.post<number>(`PovertyGuidelines/GetPovertyPercentage?tenantId=${id}`, model);
    }

    getAnnualIncome(model: IAnnualIncome, id: string) {
        return this.client.post<number>(`Uds/GetAnnualIncome?tenantId=${id}`, model);
    }

    //Medications

    searchMedications(query: string) {
        return this.client.post<Dictionary<IMedication>>(`Medications/Search?query=${query}`);
    }

    // Orderable Meds - MEDS FOR PRESCRIPTIONS
    getPrescriptionItems() {
        return this.client.get<Dictionary<IPrescriptionItem>>(`PrescriptionsItems`);
    }
    getPrescriptionItemById(id: string) {
        return this.client.get<IPrescriptionItem>(`PrescriptionsItems?id=${id}`);
    }
    searchPrescriptionItems(query: string) {
        return this.client.post<Dictionary<IPrescriptionItem>>(`PrescriptionsItems/Search?query=${query}`);
    }

    //Patient Prescriptions
    getPatientPrescriptionById(tenantId: string, patientId: string, prescriptionId: string) {
        return this.client.get<IPatientPrescription>(
            `Tenants/${tenantId}/Patients/${patientId}/PatientPrescriptions/${prescriptionId}`,
        );
    }
    addPatientPrescription(tenantId: string, patientId: string, model: IPatientPrescription) {
        return this.client.post<IPatientPrescription>(`Tenants/${tenantId}/Patients/${patientId}/PatientPrescriptions`, model);
    }
    updatePatientPrescription(tenantId: string, patientId: string, model: IPatientPrescription) {
        return this.client.put<IPatientPrescription>(`Tenants/${tenantId}/Patients/${patientId}/PatientPrescriptions`, model);
    }
    getAllPatientPrescriptions(tenantId: string, patientId: string) {
        return this.client.get<IPatientPrescription[]>(`Tenants/${tenantId}/Patients/${patientId}/PatientPrescriptions`);
    }
    getEncounterPatientPrescriptions(tenantId: string, patientId: string, encounterId: string) {
        return this.client.get<IPatientPrescription[]>(
            `Tenants/${tenantId}/Patients/${patientId}/PatientPrescriptions/ByEncounterId/${encounterId}`,
        );
    }

    // Patient Forms
    getPatientFormById(tenantId: string, patientId: string, formId: string) {
        return this.client.get<IForm>(`Tenants/${tenantId}/Patients/${patientId}/Forms?formId=${formId}`);
    }
    addPatientForm(tenantId: string, patientId: string, model: IForm) {
        return this.client.post<IForm>(`Tenants/${tenantId}/Patients/${patientId}/Forms`, model);
    }
    updatePatientForm(tenantId: string, patientId: string, model: IForm) {
        return this.client.put<IForm>(`Tenants/${tenantId}/Patients/${patientId}/Forms`, model);
    }
    getAllPatientForms(tenantId: string, patientId: string) {
        return this.client.get<IForm[]>(`Tenants/${tenantId}/Patients/${patientId}/Forms/All`);
    }
    searchPatientForms(
        tenantId: string,
        patientId: string,
        query: { encounterId?: string; appointmentId?: string; formName?: FormName; status?: string },
    ) {
        return this.client.post<IForm[]>(`Tenants/${tenantId}/Patients/${patientId}/Forms/Search`, query);
    }

    // Chart =====================================
    getChartByPatientId(tenantId: string, patientId: string) {
        return this.client.get<IChart>(`Tenants/${tenantId}/Charts/${patientId}`);
    }
    updateChart(tenantId: string, entity: IChart) {
        return this.client.put<IChart>(`Tenants/${tenantId}/Charts`, entity);
    }

    // Chart -> Procedures  =====================================
    getChartProcedures(tenantId: string, patientId: string) {
        return this.getChartEntities<IChartProcedure[]>(tenantId, patientId, 'Procedures');
    }
    getChartProcedureById(tenantId: string, patientId: string, entityId: string) {
        return this.getChartEntityById<IChartProcedure>(tenantId, patientId, 'Procedures', entityId);
    }
    updateChartProcedure(tenantId: string, patientId: string, entity: IChartProcedure) {
        return this.updateChartEntity<IChartProcedure>(tenantId, patientId, 'Procedures', entity);
    }
    createChartProcedure(tenantId: string, patientId: string, entity: IChartProcedure) {
        return this.createChartEntity<IChartProcedure>(tenantId, patientId, 'Procedures', entity);
    }

    // Chart -> Conditions  =====================================
    getChartConditions(tenantId: string, patientId: string) {
        return this.getChartEntities<IChartCondition[]>(tenantId, patientId, 'Conditions');
    }
    getChartConditionById(tenantId: string, patientId: string, entityId: string) {
        return this.getChartEntityById<IChartCondition>(tenantId, patientId, 'Conditions', entityId);
    }
    updateChartCondition(tenantId: string, patientId: string, entity: IChartCondition) {
        return this.updateChartEntity<IChartCondition>(tenantId, patientId, 'Conditions', entity);
    }
    createChartCondition(tenantId: string, patientId: string, entity: IChartCondition) {
        return this.createChartEntity<IChartCondition>(tenantId, patientId, 'Conditions', entity);
    }

    // Chart -> Dentitions  =====================================
    getChartDentitions(tenantId: string, patientId: string) {
        return this.getChartEntities<IChartDentition[]>(tenantId, patientId, 'Dentitions');
    }
    getChartDentitionByToothNumber(tenantId: string, patientId: string, toothNumber: string) {
        return this.getChartEntityById<IChartDentition>(tenantId, patientId, 'Dentitions', toothNumber);
    }
    updateChartDentition(tenantId: string, patientId: string, entity: IChartDentition) {
        return this.updateChartEntity<IChartDentition>(tenantId, patientId, 'Dentitions', entity);
    }
    createChartDentition(tenantId: string, patientId: string, entity: IChartDentition) {
        return this.createChartEntity<IChartDentition>(tenantId, patientId, 'Dentitions', entity);
    }

    // Chart -> Treatment Plans  =====================================
    getChartTreatmentPlans(tenantId: string, patientId: string) {
        return this.getChartEntities<IChartTreatmentPlan[]>(tenantId, patientId, 'TreatmentPlans');
    }
    getChartTreatmentPlanById(tenantId: string, patientId: string, entityId: string) {
        return this.getChartEntityById<IChartTreatmentPlan>(tenantId, patientId, 'TreatmentPlans', entityId);
    }
    updateChartTreatmentPlan(tenantId: string, patientId: string, entity: IChartTreatmentPlan) {
        return this.updateChartEntity<IChartTreatmentPlan>(tenantId, patientId, 'TreatmentPlans', entity);
    }
    createChartTreatmentPlan(tenantId: string, patientId: string, entity: IChartTreatmentPlan) {
        return this.createChartEntity<IChartTreatmentPlan>(tenantId, patientId, 'TreatmentPlans', entity);
    }

    // Chart -> Treatment Plans -> Phases =====================================
    getChartTreatmentPlanPhases(tenantId: string, treatmentPlanId: string) {
        return this.client.get<IChartTreatmentPlanPhase[]>(`Tenants/${tenantId}/TreatmentPlans/${treatmentPlanId}/Phases`);
    }
    getChartTreatmentPlanPhaseById(tenantId: string, treatmentPlanId: string, phaseId: string) {
        return this.client.get<IChartTreatmentPlanPhase>(
            `Tenants/${tenantId}/TreatmentPlans/${treatmentPlanId}/Phases/${phaseId}`,
        );
    }
    createChartTreatmentPlanPhase(tenantId: string, treatmentPlanId: string, phase: IChartTreatmentPlanPhase) {
        return this.client.post<IChartTreatmentPlanPhase>(`Tenants/${tenantId}/TreatmentPlans/${treatmentPlanId}/Phases`, phase);
    }
    updateChartTreatmentPlanPhase(tenantId: string, treatmentPlanId: string, phase: IChartTreatmentPlanPhase) {
        return this.client.put(`Tenants/${tenantId}/TreatmentPlans/${treatmentPlanId}/Phases`, phase);
    }

    // Chart -> PerioExams  =====================================
    getPerioExams(tenantId: string, patientId: string) {
        return this.getChartEntities<IPerioExam[]>(tenantId, patientId, 'PerioExams');
    }
    getPerioExamById(tenantId: string, patientId: string, entityId: string) {
        return this.getChartEntityById<IPerioExam>(tenantId, patientId, 'PerioExams', entityId);
    }
    updatePerioExam(tenantId: string, patientId: string, entity: IPerioExam) {
        return this.updateChartEntity<IPerioExam>(tenantId, patientId, 'PerioExams', entity);
    }
    createPerioExam(tenantId: string, patientId: string, entity: IPerioExam) {
        return this.createChartEntity<IPerioExam>(tenantId, patientId, 'PerioExams', entity);
    }

    // Chart -> Notes  =====================================
    getChartNotes(tenantId: string, patientId: string) {
        return this.getChartEntities<IChartNote[]>(tenantId, patientId, 'Notes');
    }
    getChartNoteById(tenantId: string, patientId: string, entityId: string) {
        return this.getChartEntityById<IChartNote>(tenantId, patientId, 'Notes', entityId);
    }
    updateChartNote(tenantId: string, patientId: string, entity: IChartNote) {
        return this.updateChartEntity<IChartNote>(tenantId, patientId, 'Notes', entity);
    }
    getChartNoteByEncounterId(tenantId: string, patientId: string, encounterId: string) {
        return this.client.get<IChartNote>(`Tenants/${tenantId}/Charts/${patientId}/Notes/ByEncounterId/${encounterId}`);
    }
    getChartNoteByAppointmentId(tenantId: string, patientId: string, appointmentId: string) {
        return this.client.get<IChartNote>(`Tenants/${tenantId}/Charts/${patientId}/Notes/ByAppointmentId/${appointmentId}`);
    }

    // Chart -> Note Templates  =====================================
    getQuickNoteTemplates(tenantId: string) {
        return this.getTenantEntities<Dictionary<IQuickNoteTemplate>>(tenantId, 'QuickNoteTemplate');
    }
    getQuickNoteTemplateById(tenantId: string, entityId: string) {
        return this.getTenantEntityById<IQuickNoteTemplate>(tenantId, 'QuickNoteTemplate', entityId);
    }
    updateQuickNoteTemplate(tenantId: string, entity: IQuickNoteTemplate) {
        return this.updateTenantEntity<IQuickNoteTemplate>(tenantId, 'QuickNoteTemplate', entity);
    }
    createQuickNoteTemplate(tenantId: string, entity: IQuickNoteTemplate) {
        return this.createTentantEntity<IQuickNoteTemplate>(tenantId, 'QuickNoteTemplate', entity);
    }

    // Chart Entity Helpers
    private getChartEntities<T>(tenantId: string, patientId: string, entityEndpoint: ChartEntities) {
        return this.client.get<T>(`Tenants/${tenantId}/Charts/${patientId}/${entityEndpoint}`);
    }
    private getChartEntityById<T>(tenantId: string, patientId: string, entityEndpoint: ChartEntities, entityId: string) {
        return this.client.get<T>(`Tenants/${tenantId}/Charts/${patientId}/${entityEndpoint}/${entityId}`);
    }
    private updateChartEntity<T>(tenantId: string, patientId: string, entityEndpoint: ChartEntities, entity: T) {
        return this.client.put<T>(`Tenants/${tenantId}/Charts/${patientId}/${entityEndpoint}`, entity);
    }
    private createChartEntity<T>(tenantId: string, patientId: string, entityEndpoint: ChartEntities, entity: T) {
        return this.client.post<T>(`Tenants/${tenantId}/Charts/${patientId}/${entityEndpoint}`, entity);
    }

    // Tenant Entity Helpers
    private getTenantEntities<T>(tenantId: string, entityEndpoint: TenantEntities) {
        return this.client.get<T>(`Tenants/${tenantId}/${entityEndpoint}`);
    }
    private getTenantEntityById<T>(tenantId: string, entityEndpoint: TenantEntities, entityId: string) {
        return this.client.get<T>(`Tenants/${tenantId}/${entityEndpoint}/${entityId}`);
    }
    private updateTenantEntity<T>(tenantId: string, entityEndpoint: TenantEntities, entity: T) {
        return this.client.put<T>(`Tenants/${tenantId}/${entityEndpoint}`, entity);
    }
    private createTentantEntity<T>(tenantId: string, entityEndpoint: TenantEntities, entity: T) {
        return this.client.post<T>(`Tenants/${tenantId}/${entityEndpoint}`, entity);
    }

    // ChartTreatmentPlanPhaseProcedures =====================================
    getChartTreatmentPlanPhaseProcedures(tenantId: string, patientId: string) {
        return this.client.get<IChartTreatmentPlanPhaseProcedure[]>(
            `Tenants/${tenantId}/Patients/${patientId}/TreatmentPlanPhaseProcedures`,
        );
    }

    // PendingChartTreatmentPlanPhaseProcedures =====================================
    getPendingChartTreatmentPlanPhaseProcedures(tenantId: string, patientId: string) {
        return this.client.get<IPendingChartTreatmentPlanPhaseProcedure[]>(
            `Tenants/${tenantId}/Patients/${patientId}/PendingChartTreatmentPlanPhaseProcedures`,
        );
    }

    // Payers =====================================
    getPayers() {
        return this.client.get<Dictionary<IPayer>>('Payers', {
            headers: { 'Cache-Control': 'private, max-age=3600', Pragma: '' },
        });
    }

    getTenantPayers(tenantId: string) {
        return this.client.get<Dictionary<ITenantPayer>>(`Tenants/${tenantId}/TenantPayers`);
    }
    getPayerDetails(tenantId: string) {
        return this.client.get<Dictionary<IPayerDetail>>(`Tenants/${tenantId}/TenantPayers/Details`);
    }
    getPayerDetailsById(tenantId: string, payerId: string) {
        return this.client.get<IPayerDetail>(`Tenants/${tenantId}/TenantPayers/${payerId}/Details`);
    }

    // Procedure Categories  =====================================
    getProcedureCategories() {
        return this.client.get<IProcedureCategories>('ProcedureCategories');
    }

    // Procedures =====================================
    getProcedures(tenantId: string) {
        return this.client.get<Dictionary<IProcedure>>(`Tenants/${tenantId}/TenantProcedures`, {
            headers: { 'Cache-Control': 'private, max-age=3600' },
        });
    }
    getProcedureById(entityId: string) {
        return this.client.get<IProcedure>(`Procedures/${entityId}`);
    }
    createProcedure(entity: IProcedure) {
        return this.client.post<IProcedure>(`Procedures`, entity);
    }
    updateProcedure(entity: IProcedure) {
        return this.client.put<IProcedure>(`Procedures`, entity);
    }

    // Billing Procedures
    getPatientBillingProcedures(tenantId: string, patientId: string) {
        return this.client.get<IBillingProcedure[]>(`Tenants/${tenantId}/Patients/${patientId}/BIllingProcedures`);
    }

    // Diagnoses =====================================
    getDiagnoses() {
        return this.client.get<Dictionary<IDiagnosis>>('Diagnoses');
    }
    getDiagnosisById(entityId: string) {
        return this.client.get<IDiagnosis>(`Diagnoses/${entityId}`);
    }
    createDiagnosis(tenantId: string, entity: IDiagnosis) {
        return this.client.post<IDiagnosis>('Diagnoses', entity);
    }
    updateDiagnosis(tenantId: string, entity: IDiagnosis) {
        return this.client.put<IDiagnosis>('Diagnoses', entity);
    }
    // Conditions =====================================
    getConditions() {
        return this.getPlatformLookupEntities<ICondition>('Conditions');
    }

    // SlidingFeePrograms =====================================
    getSlidingFeePrograms(tenantId: string) {
        return this.getLookupEntities<ISlidingFeeProgram>(tenantId, 'SlidingFeePrograms');
    }
    getSlidingFeeProgramById(tenantId: string, slidingFeeId: string) {
        return this.getLookupEntityById<ISlidingFeeProgram>(tenantId, 'SlidingFeePrograms', slidingFeeId);
    }

    getSlidingFeeProgramByPatient(tenantId: string, patientId: string) {
        return this.client.get<ISlidingFeePlan>(`Tenants/${tenantId}/SlidingFeePrograms/Patient/${patientId}`);
    }
    getSlidingFeeProgramByPatientPercentage(tenantId: string, patientId: string, percentage: number) {
        return this.client.get<ISlidingFeePlan>(
            `Tenants/${tenantId}/SlidingFeePrograms/Patient/${patientId}/Percentage${percentage}`,
        );
    }

    //

    // Subscriptions =====================================

    getSubscriptions(tenantId: string) {
        return this.client.get<ITenantSubscription[]>(`Tenants/${tenantId}/Subscriptions`);
    }

    getSubscriptionTenantUsers(tenantId: string, productId: string) {
        return this.client.get<Dictionary<ITenantUser>>(`Tenants/${tenantId}/Subscriptions/${productId}/Users`);
    }

    // Providers =====================================
    getProviders(tenantId: string) {
        return this.getLookupEntities<IProvider>(tenantId, 'Providers');
    }
    getProviderById(tenantId: string, entityId: string) {
        return this.getLookupEntityById<IProvider>(tenantId, 'Providers', entityId);
    }
    createProvider(tenantId: string, entity: IProvider) {
        return this.createLookupEntity<IProvider>(tenantId, 'Providers', entity);
    }
    UpdateProvider(tenantId: string, entity: IProvider) {
        return this.updateLookupEntity<IProvider>(tenantId, 'Providers', entity);
    }

    // Languages =====================================
    getLanguages() {
        return this.client.get<Dictionary<ILanguage>>(`Languages`);
    }

    //HoldiingStatement =====================================
    getHoldingStatement() {
        return this.client.get<Dictionary<IHoldingStatement>>(`HoldStatements`);
    }

    // Quick Action categories =====================================
    getQuickActionCategories(tenantId: string) {
        return this.client.get<IQuickActionCategories>(`Tenants/${tenantId}/QuickActionCategories`);
    }
    getQuickActionCategoryById(tenantId: string, entityId: string) {
        return this.getLookupEntityById<IQuickActionCategory>(tenantId, 'Procedures', entityId);
    }
    createQuickActionCategory(tenantId: string, entity: IQuickActionCategory) {
        return this.createLookupEntity<IQuickActionCategory>(tenantId, 'QuickActionCategories', entity);
    }
    updateQuickActionCategory(tenantId: string, entity: IQuickActionCategories) {
        return this.client.put<IQuickActionCategories>(`Tenants/${tenantId}/QuickActionCategories`, entity);
    }
    updateQuickActionsOrder(tenantId: string, entityId: string, order: number) {
        return this.client.get<IQuickActionCategory>(
            `Tenants/${tenantId}/QuickActionCategories/${entityId}/UpdateOrder/${order}`,
        );
    }

    // Quick Actions =====================================
    getQuickActions(tenantId: string) {
        return this.client.get<IQuickActions>(`Tenants/${tenantId}/QuickActions`);
    }
    getQuickActionById(tenantId: string, entityId: string) {
        return this.getLookupEntityById<IQuickActions>(tenantId, 'Procedures', entityId);
    }
    createQuickAction(tenantId: string, entity: IQuickActions) {
        return this.createLookupEntity<IQuickActions>(tenantId, 'QuickActions', entity);
    }
    updateQuickAction(tenantId: string, entity: IQuickActions) {
        return this.updateLookupEntity<IQuickActions>(tenantId, 'QuickActions', entity);
    }

    //Reporiting Catgories

    getReportingCategories() {
        return this.client.get<Dictionary<IReportingCategory>>('ReportingCategories');
    }
    getTenantReportingCategories(tenantId: string) {
        return this.client.get<Dictionary<IReportingCategory>>(`ReportingCategories/${tenantId}`);
    }
    createTenantReportingCategories(tenantId: string, model: IReportingCategory) {
        return this.client.post<IReportingCategory>(`ReportingCategories/${tenantId}`, model);
    }
    updateTenantReportingCategories(tenantId: string, model: IReportingCategory) {
        return this.client.put<IReportingCategory>(`ReportingCategories/${tenantId}`, model);
    }

    //Reporiting Groups

    getReportingGroups() {
        return this.client.get<Dictionary<IReportingGroup>>('ReportingGroups');
    }
    getTenantReportingGroups(tenantId: string) {
        return this.client.get<Dictionary<IReportingGroup>>(`ReportingGroups/${tenantId}`);
    }
    createTenantReportingGroup(tenantId: string, model: IReportingGroup) {
        return this.client.post<IReportingGroup>(`ReportingGroups/${tenantId}`, model);
    }
    updateTenantReportingGroup(tenantId: string, model: IReportingGroup) {
        return this.client.put<IReportingGroup>(`ReportingGroups/${tenantId}`, model);
    }

    //report Type
    getReportingTypes() {
        return this.client.get<Dictionary<IReportingType>>('ReportingTypes');
    }
    getTentantReportingTypes(tenantId: string) {
        return this.client.get<Dictionary<IReportingType>>(`ReportingTypes/${tenantId}`);
    }
    createTentantReportingTypes(tenantId: string, model: IReportingType) {
        return this.client.post<IReportingType>(`ReportingTypes/${tenantId}`, model);
    }
    updateTentantReportingTypes(tenantId: string, model: IReportingType) {
        return this.client.put<IReportingType>(`ReportingTypes/${tenantId}`, model);
    }

    // Platform Lookup Entity
    getPlatformLookupEntities<T>(entityEndpoint: DentalLookupEntities) {
        return this.client.get<Dictionary<T>>(`${entityEndpoint}`, {
            headers: { 'Cache-Control': 'private, max-age=3600' },
        });
    }
    getPlatformLookupEntityById<T>(entityEndpoint: DentalLookupEntities, entityId: string) {
        return this.client.get<T>(`${entityEndpoint}/${entityId}`);
    }
    createPlatformLookupEntity<T>(entityEndpoint: DentalLookupEntities, entity: T) {
        return this.client.post<T>(`${entityEndpoint}`, entity);
    }
    updatePlatformLookupEntity<T>(entityEndpoint: DentalLookupEntities, entity: T) {
        return this.client.put<T>(`${entityEndpoint}`, entity);
    }

    // Entity Helpers =====================================
    getLookupEntities<T>(tenantId: string, entityEndpoint: DentalLookupEntities) {
        return this.client.get<Dictionary<T>>(`Tenants/${tenantId}/${entityEndpoint}`, {
            headers: { 'Cache-Control': 'private, max-age=3600' },
        });
    }
    getLookupEntityById<T>(tenantId: string, entityEndpoint: DentalLookupEntities, entityId: string) {
        return this.client.get<T>(`Tenants/${tenantId}/${entityEndpoint}/${entityId}`);
    }
    createLookupEntity<T>(tenantId: string, entityEndpoint: DentalLookupEntities, entity: T) {
        return this.client.post<T>(`Tenants/${tenantId}/${entityEndpoint}`, entity);
    }
    updateLookupEntity<T>(tenantId: string, entityEndpoint: DentalLookupEntities, entity: T) {
        return this.client.put<T>(`Tenants/${tenantId}/${entityEndpoint}`, entity);
    }

    // Tenant Users =====================================
    /**
     * account: ITenantUserModel
     * user: IUserIdentity
     */

    getTenantUsers(tenantId: string) {
        return this.client.get<Dictionary<ITenantUser>>(`Tenants/${tenantId}/Subscriptions/dental/Users`);
    }
    getTenantUserById(tenantId: string, user: string) {
        return this.client.get<ITenantUser>(`Tenants/${tenantId}/Users/${user}`);
    }
    createTenantUser(tenantId: string, user: ITenantUser) {
        return this.client.post<ITenantUser>(`Tenants/${tenantId}/Users`, user);
    }
    updateTenantUser(tenantId: string, user: ITenantUser) {
        return this.client.put<ITenantUser>(`Tenants/${tenantId}/Users`, user);
    }
    addOrUpdateIdentity(tenantId: string, identity: IUserIdentity) {
        return this.client.put<IUserIdentity>(`Tenants/${tenantId}/Users/Identities`, identity);
    }

    // Tenant [Not Used] =====================================
    getTenants() {
        return this.client.get<ITenant[]>(`Tenants`);
    }
    getTenantById(tenantId: string) {
        return this.client.get<ITenant>(`Tenants/${tenantId}`);
    }

    // Encounter =====================================
    getPatientEncounters(tenantId: string, patientId: string) {
        return this.client.get<IPatientEncounter[]>(`Tenants/${tenantId}/patients/${patientId}/encounters`);
    }
    getPatientEncounterById(tenantId: string, patientId: string, encounterId: string) {
        return this.client.get<IPatientEncounter>(`Tenants/${tenantId}/patients/${patientId}/encounters/${encounterId}`);
    }
    getPatientEncounterWithTasks(tenantId: string, patientId: string, encounterId: string) {
        return this.client.get<IPatientEncounterWithTasks>(
            `Tenants/${tenantId}/patients/${patientId}/encounters/${encounterId}/WithTasks`,
        );
    }
    updatePatientEncounter(tenantId: string, patientId: string, encounter: IPatientEncounter) {
        return this.client.put<IPatientEncounter>(`Tenants/${tenantId}/patients/${patientId}/encounters`, encounter);
    }
    updatePatientEncounterProviderIdByProviderIdType(
        tenantId: string,
        patientId: string,
        encounterId: string,
        providerIdType: 'supervisingProviderId' | 'treatingProviderId' | 'billingProviderId' | 'attestingProviderId',
        providerId: string,
    ) {
        return this.client.put<IPatientEncounter>(
            `Tenants/${tenantId}/Patients/${patientId}/Encounters/${encounterId}/Provider/${providerId}/${providerIdType}`,
        );
    }
    createPatientEncounter(tenantId: string, patientId: string, encounter: IPatientEncounter) {
        return this.client.post<IPatientEncounter>(`Tenants/${tenantId}/patients/${patientId}/encounters`, encounter);
    }
    starPatientEncounter(tenantId: string, appointmentId: string, data: { patientId: string; locationOfCareId: string }) {
        return this.client.put<string>(`Tenants/${tenantId}/Appointments/${appointmentId}/Encounters/StartEncounter`, data);
    }

    getPatientAppointmentByEncounterId(tenantId: string, encounterId: string) {
        return this.client.get<IPatientAppointment[]>(`Tenants/${tenantId}/Appointments/Encounters/${encounterId}`);
    }

    getPatientIncompleteEncounters(tenantId: string, patientId: string) {
        return this.client.get<IPatientEncounterWithAppointment[]>(
            `Tenants/${tenantId}/Patients/${patientId}/Encounters/IncompleteWithAppointments`,
        );
    }
    getPastEncounters(tenantId: string, patientId: string) {
        return this.client.get<IEncounterHistory[]>(`Tenants/${tenantId}/Patients/${patientId}/Encounters/Past`);
    }
    getPatientPastHistory(tenantId: string, patientId: string) {
        return this.client.get<IAppointmentHistory[]>(`Tenants/${tenantId}/Patients/${patientId}/Encounters/Past`);
    }
    getPatientPastAppointments(tenantId: string, patientId: string) {
        return this.client.get<IAppointmentHistory[]>(`Tenants/${tenantId}/Appointments/Patients/${patientId}/Past`);
    }
    getWorkListDataByWorkList<T = unknown[]>(params: GetWorkListDataByWorkListParams) {
        const {
            tenantId,
            cancelToken,
            filters,
            method,
            pageNumber,
            pageSize,
            continuationToken,
            worklistPayerSource,
        } = params;
        const reasonCodes = filters?.reasonCodes ? (filters?.reasonCodes as ITag[]).map((tag) => tag.key) : undefined;

        const queryFilters = {
            ...(filters ?? {}),
            reasonCodes,
            pageNumber,
            pageSize,
            worklistPayerSource,
            continuationToken,
        };

        return this.client.post<T>(`Tenants/${tenantId}/Worklists/${method}`, queryFilters, { cancelToken });
    }
    updateAppointmentWorkListItem({
        tenantId,
        appointment,
        recordId: appointmentId,
        patient,
        updateMethod,
    }: WorkListAppointmentUpdateParams & { updateMethod: string }) {
        return this.client.put<IWorkListAppointmentView>(`Tenants/${tenantId}/Worklists/${updateMethod}/${appointmentId}`, {
            appointment,
            patient,
        });
    }
    updateEncounterWorkListItem({
        tenantId,
        encounter,
        recordId: encounterId,
        patient,
        updateMethod,
    }: WorkListEncounterUpdateParams & { updateMethod: string }) {
        return this.client.put<IWorkListEncounterView>(`Tenants/${tenantId}/Worklists/${updateMethod}/${encounterId}`, {
            encounter,
            patient,
        });
    }
    getCorrectionAmendEncountersCount(tenantId: string, providerId: string) {
        return this.client.get(`Tenants/${tenantId}/Worklists/CorrectionAmendEncountersCount?providerId=${providerId}`);
    }
    getAwaitingAttestionCount(tenantId: string, filters?: Record<string, unknown>) {
        return this.client.post(`Tenants/${tenantId}/Worklists/AwaitingAttestationCount`, filters);
    }
    recalculateEncounter(tenantId: string, patientId: string, encounterId: string) {
        return this.client.post<IWorkListEncounterView>(
            `Tenants/${tenantId}/Patients/${patientId}/Encounters/${encounterId}/Snapshot`,
        );
    }
    voidNonClinicalProcedure(tenantId: string, patientId: string, encounterId: string, billingProcedureId: string) {
        return this.client.get<IBillingProcedure>(
            `Tenants/${tenantId}/Patients/${patientId}/BillingEncounters/${encounterId}/VoidNonClinicalProcedure/${billingProcedureId}`,
        );
    }
    searchCarcRarcsDetails(query: string) {
        return this.client.post<Record<string, ICarcRarcsDetail>>(`CarcRarcsDetail/Search?query=${query}`);
    }
    getEncounterWorkListDetail(tenantId: string, detailType: WorkListDetail) {
        const lookupEncounterDetailPath = {
            [WorkListDetail.ReadyToReviewFeeSum]: 'Encounters/CalculateReadyToReviewFeesSum',
            [WorkListDetail.PatientOpenBilledEncountersSum]: 'OpenBilledEncounters/Patient/TotalFees',
            [WorkListDetail.InsuranceOpenBilledEncountersSum]: 'OpenBilledEncounters/Insurance/TotalFees',
            [WorkListDetail.DeniedEncountersFeeSum]: 'DeniedEncounters/TotalFees',
            [WorkListDetail.ReadyToRebillEncountersFeesSum]: 'ReadyToRebillEncounters/false/TotalFees',
            [WorkListDetail.ReadyToRebillEncountersOnHoldFeesSum]: 'ReadyToRebillEncounters/true/TotalFees',
            [WorkListDetail.EditEncountersFeeSum]: 'CorrectionsNeededEncounters/TotalFees',
        };
        return this.client.get<number>(`Tenants/${tenantId}/${lookupEncounterDetailPath[detailType]}`);
    }
    getDashboardTotalFees(tenantId: string) {
        return this.client.get<DashboardBoardTotals>(`Tenants/${tenantId}/DashboardTotalFees`);
    }
    // Toggle a ready-to-review encounter to be on admin hold or in the billing queue
    setEncounterOnHoldStatus(tenantId: string, encounterId: string, payload: EncounterHoldStatusPayload) {
        return this.client.post<IWorkListEncounterView>(`Tenants/${tenantId}/Encounter/${encounterId}/HoldStatus`, payload);
    }

    markEncountersAsBilled(tenantId: string, encounterIds: string[]) {
        return this.client.put<IWorkListEncounterView[]>(`Tenants/${tenantId}/Encounters/MarkBilled`, encounterIds);
    }
    markEncountersAsBilledWithBatch(tenantId: string, encounterIds: string[], batchId: string) {
        return this.client.put<IWorkListEncounterView[]>(`Tenants/${tenantId}/Encounters/MarkBilledBatch`, {
            ids: encounterIds,
            batchId,
        });
    }
    markEncountersAsRebilled(tenantId: string, encounterIds: string[]) {
        return this.client.put<string[]>(`Tenants/${tenantId}/Encounters/ReBills`, encounterIds);
    }
    markEncountersAsRebilledWithBatch(tenantId: string, encounterIds: string[], batchId: string) {
        return this.client.put<string[]>(`Tenants/${tenantId}/Encounters/ReBillsBatch`, { ids: encounterIds, batchId });
    }
    createAmendmentNote(tenantId: string, queryObject: IAmendmentNote) {
        return this.client.post<boolean>(`Tenants/${tenantId}/Encounters/AmendmentNote`, queryObject);
    }
    updateCorrectionsNeededIsRcm(tenantId: string, workListEncounterViewId: string, isRcm: boolean) {
        return this.client.put(
            `Tenants/${tenantId}/Worklists/UpdateCorrectionsNeededIsRcm/${workListEncounterViewId}?isRcm=${isRcm}`,
        );
    }

    //Encounter Correspondence =====================
    getEncounterCorrespondence(tenantId: string, encounterId: string) {
        return this.client.get<IEncounterCorrespondence[]>(`Tenants/${tenantId}/Encounters/${encounterId}/Correspondence`);
    }
    saveEncounterCorrespondence(tenantId: string, encounterId: string, correspondence: IEncounterCorrespondence) {
        return this.client.put<IEncounterCorrespondence[]>(
            `Tenants/${tenantId}/Encounters/${encounterId}/Correspondence`,
            correspondence,
        );
    }

    // Encounter Status ============================
    getPatientEncounterStatuses() {
        return this.client.get<Dictionary<IEncounterStatus>>(`EncounterStatuses`);
    }

    // Encounter Reasons ===========================
    getEncounterReasons(tenantId: string) {
        return this.client.get<Dictionary<IEncounterReason>>(`Tenants/${tenantId}/EncounterReasons`);
    }
    getEncounterReasonsById(tenantId: string, encounterId: string) {
        return this.client.get<IEncounterReason>(`Tenants/${tenantId}/EncounterReasons/${encounterId}`);
    }

    createEncounterReason(tenantId: string, model: IEncounterReason) {
        return this.client.post<IEncounterReason>(`Tenants/${tenantId}/EncounterReasons`, model);
    }

    updateEncounterReason(tenantId: string, model: IEncounterReason) {
        return this.client.put<IEncounterReason>(`Tenants/${tenantId}/EncounterReasons`, model);
    }
    // Tasks =====================================
    getTasksByTenants(tenantId: string) {
        return this.client.get<IUserTask[]>(`UserTasks/Tenants/${tenantId}`);
    }
    getTasksByTarget(tenantId: string, target: TaskTargets, queryObject?: GetTasksByTargetQuery) {
        const query = queryObject ? queryString.stringify(queryObject) : '';
        return this.client.get<IUserTask[]>(`UserTasks/Tenants/${tenantId}/Targets/${target}?${query}`);
    }
    getTasksByReferences(tenantId: string, queryObject: GetTasksByTargetQuery) {
        const query = queryString.stringify(queryObject);
        return this.client.get<IUserTask[]>(`UserTasks/Tenants/${tenantId}/References?${query}`);
    }
    getTasksByGroupForPatient(tenantId: string, patientId: string, group: TaskGroup, references?: Record<string, string>) {
        const query = references ? queryString.stringify(references) : '';
        return this.client.get<IUserTask[]>(
            `UserTasks/Tenants/${tenantId}/ForPatient/${patientId}/ByGroup/${group}${query ? `?${query}` : ''}`,
        );
    }
    getTasksByFilter(tenantId: string, queryObject: GetTasksByFilterQuery) {
        const query = queryString.stringify(queryObject);
        return this.client.get<IUserTask[]>(`UserTasks/Tenants/${tenantId}/Filtered?${query}`);
    }

    // Benefit Plans
    getBenefitPlans() {
        return this.client.get<BenefitPlans>('BenefitPlans');
    }
    getBenefitPlanById(benefitPlanId: string) {
        return this.client.get<IBenefitPlan>(`BenefitPlans/${benefitPlanId}`);
    }

    // Imaging Bridge

    getImagingUrl(tenantId: string, patientToSearch: IPatientImagingModel) {
        return this.client.post<string>(`Tenants/${tenantId}/ImagingSource/PatientUrl`, patientToSearch);
    }
    getImagingSourcesByTenant(tenantId: string) {
        return this.client.get<Dictionary<IImagingSource>>(`Tenants/${tenantId}/ImagingSource`);
    }
    getImagingSourceById(tenantId: string, id: string) {
        return this.client.get<IBatch>(`Tenants/${tenantId}/ImagingSource/${id}`);
    }
    createImagingSource(tenantId: string, batch: IImagingSource) {
        return this.client.post<IBatch>(`Tenants/${tenantId}/ImagingSource`, batch);
    }
    updateImagingSource(tenantId: string, imageSource: IImagingSource) {
        return this.client.put<IBatch>(`Tenants/${tenantId}/ImagingSource`, imageSource);
    }

    getImagingSourceGeneric() {
        return this.client.get<Dictionary<IImagingSourceGeneric>>(`ImagingSourceGeneric`);
    }
    getImagingSourceGenericById(tenantId: string, id: string) {
        return this.client.get<IImagingSourceGeneric>(`Tenants/${tenantId}/ImagingSourceGeneric/${id}`);
    }
    createImagingSourceGeneric(tenantId: string, batch: IImagingSourceGeneric) {
        return this.client.post<IImagingSourceGeneric>(`Tenants/${tenantId}/ImagingSourceGeneric`, batch);
    }
    updateImagingSourceGeneric(tenantId: string, imageSource: IImagingSourceGeneric) {
        return this.client.put<IImagingSourceGeneric>(`Tenants/${tenantId}/ImagingSourceGeneric`, imageSource);
    }

    // SignalR
    getSubscribeToGroup(tenantId: string, connectionId: string | null, groupName?: string) {
        const queryObject = { connectionId, groupName  };
        const query = queryObject ? queryString.stringify(queryObject) : '';
        return this.client.get(`Tenants/${tenantId}/SignalR/SubscribeToGroup?${query}`);
    }
    getUnsubscribeFromGroup(tenantId: string, connectionId: string | null, groupName?: string) {
        const queryObject = { connectionId, groupName  };
        const query = queryObject ? queryString.stringify(queryObject) : '';
        return this.client.get(`Tenants/${tenantId}/SignalR/UnsubscribeFromGroup?${query}`);
    }
    // Lookups =====================================
    getSexualOrientations() {
        return this.client.get<Record<string, LookupCodeBase>>(`SexualOrientation`);
    }
    getEthnicities() {
        return this.client.get<Dictionary<LookupBase>>(`Ethnicities`);
    }
    getLegalSexes() {
        return this.client.get<Dictionary<ILegalSex>>(`LegalSexes`);
    }
    //Pay Periods =====================================
    getPayPeriods() {
        return this.client.get<Dictionary<LookupBase>>(`PayPeriods`);
    }
    getGenderIdentities() {
        return this.client.get<Dictionary<IGenderIdentity>>(`GenderIdentities`);
    }
    getRaces() {
        return this.client.get<Dictionary<Race>>(`Races`);
    }
    getDosageFrequency() {
        return this.client.get<Record<string, IDosageFrequency>>(`DosageFrequency`);
    }
    getDosageQuantity() {
        return this.client.get<Record<string, IDosageQuantity>>(`DosageQuantity`);
    }
    getAdditionalInstructions() {
        return this.client.get<Dictionary<LookupBase>>(`AdditionalInstructions`);
    }

    getAmendmentReasons() {
        return this.client.get<Dictionary<LookupBase>>(`AmendmentReasons`);
    }

    // Embed Reports
    getReports(tenantId: string) {
        return this.client.get<Dictionary<IReport>>(`Tenants/${tenantId}/EmbeddedReport`);
    }
    getPerioReport(tenantId: string, patientId: string, examIds: string[]) {
        const requestBody = { patientId, examIds };
        return this.client.post<IEmbedReportModel>(`Tenants/${tenantId}/EmbeddedReport/PerioChart`, requestBody);
    }
    queryReport(query: ReportQuery<Record<string, any>>, type: ReportType) {
        const { tenantId } = query;
        const baseURL = `Tenants/${tenantId}/EmbeddedReport/`;

        const extraParamsQueryString = query.extraParamsQueryString;

        const queryStringObject = extraParamsQueryString ? ({ ...query } as any) : undefined;
        if (queryStringObject) {
            delete queryStringObject.tenantId;
            delete queryStringObject.extraParamsQueryString;
        }

        const parsedQueryString = queryStringObject ? `?${queryString.stringify(queryStringObject)}` : '';
        return this.client.post<IEmbedReportModel>(`${baseURL}${type}${parsedQueryString}`, query);
    }
    // Batches
    getBatches(tenantId: string) {
        const query = queryString.stringify({ tenantId });
        return this.client.get<Dictionary<IBatch>>(`Batches?${query}`);
    }
    getHardCloseThroughDate(tenantId: string) {
        const query = queryString.stringify({ tenantId });
        return this.client.get<string>(`Batches/HardCloseThroughDate?${query}`);
    }
    getBatch(tenantId: string, id: string) {
        return this.client.get<IBatch>(`Batches/${id}`);
    }
    createBatch(tenantId: string, batch: IBatch) {
        const query = queryString.stringify({ tenantId });
        return this.client.post<IBatch>(`Batches?${query}`, batch);
    }
    updateBatch(tenantId: string, batch: IBatch) {
        const query = queryString.stringify({ tenantId });
        return this.client.put<IBatch>(`Batches?${query}`, batch);
    }

    //Checkout
    checkoutAppointment(tenantId: string, checkoutModel: IAppointmentCheckoutModel) {
        return this.client.put<IAppointmentCheckoutModel>(`Tenants/${tenantId}/Appointments/Patients/Checkout`, checkoutModel);
    }

    // Billing Procedure - Calculation End-points
    getBillingProceduresByType(
        tenantId: string,
        patientId: string,
        type: 'LedgerBillingProcedures' | 'CheckoutBillingProcedures',
        cancelToken?: CancelToken,
    ) {
        return this.client.get<IBillingProcedure[]>(`Tenants/${tenantId}/Patients/${patientId}/${type}`, { cancelToken });
    }
    getCheckoutBillingProcedures(tenantId: string, patientId: string, query?: { appointmentId?: string }) {
        const stringQuery = query ? `?${queryString.stringify(query)}` : '';
        return this.client.get<IBillingProcedure[]>(
            `Tenants/${tenantId}/Patients/${patientId}/CheckoutBillingProcedures${stringQuery}`,
        );
    }
    getTreatmentPlanBillingProcedures(tenantId: string, patientId: string, query?: { treatmentPlanId?: string }) {
        const stringQuery = query ? `?${queryString.stringify(query)}` : '';
        return this.client.get<IBillingProcedure[]>(
            `Tenants/${tenantId}/Patients/${patientId}/TreatmentPlannedBillingProcedures${stringQuery}`,
        );
    }
    getMostRecentTreatmentPlanView(tenantId: string, patientId: string) {
        return this.client.get<IMostRecentTreatmentPlanView>(
            `Tenants/${tenantId}/Patients/${patientId}/MostRecentTreatmentPlanView`,
        );
    }

    //Alerts

    getAlertTypes() {
        return this.client.get<ClinicalAlertTypeLookup>(`AlertTypes`);
    }
    getPatientClinicalAlerts(tenantId: string, patientId: string) {
        return this.client.get<IClinicalAlerts>(`Tenants/${tenantId}/ClinicalAlerts/${patientId}`);
    }
    // getPatientClinicalAlertsByType(tenantId: string, patientId: string, type: ClinicalAlertType) {
    //     return this.client.get<IClinicalAlerts>(`Tenants/${tenantId}/ClinicalAlerts/Patients/${patientId}/Type/${type}`);
    // }
    // addClinicalAlert(tenantId: string, clinicalAlert: IClinicalAlert) {
    //     return this.client.post<IClinicalAlert>(`Tenants/${tenantId}/ClinicalAlerts`, clinicalAlert);
    // }
    addUpdateClinicalAlert(tenantId: string, clinicalAlert: IClinicalAlert) {
        return this.client.put<IClinicalAlert>(`Tenants/${tenantId}/ClinicalAlerts`, clinicalAlert);
    }

    getTenantFeatureFlagsForTenant(tenantId: string) {
        return this.client.get<FeatureFlag[]>(`FeatureFlags/Tenant/${tenantId}`);
    }

    getEncounterStatusOptions() {
        return this.client.get<Dictionary<LookupCodeBase>>(`/dropdownOptions/EncounterStatus`);
    }
    getPatientTrackerOptions() {
        return this.client.get<Dictionary<LookupCodeBase>>(`/dropdownOptions/PatientTrackerStatus`);
    }

    getStatementConfigurations(tenantId: string) {
        return this.client.get<IStatementConfig>(`Tenants/${tenantId}/StatementConfigurations`);
    }
}

const dentalApi = new DentalApi();
export default dentalApi;

type GetTasksByTargetQuery = {
    patientId: string;
    encounterId?: string;
    appointmentId?: string;
    tenantId?: string;
};

export type GetTasksByFilterQuery = { target?: TaskTargets; type?: TaskType; providerId?: string };
