import {
    DirectionalHint,
    Dropdown,
    DropdownMenuItemType,
    Icon,
    IDropdownOption,
    Label,
    Link,
    Spinner,
    Stack,
    Text,
    TooltipDelay,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { EncounterStatus, encounterStatusText, IPatientEncounter } from 'api/models/encounter.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { usePatientId, useSelector, useTenant, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { find, groupBy, map, orderBy } from 'lodash';
import TrackerStatusDropdown, { TrackerStatus } from 'pages/Scheduling/components/TrackerStatusDropdown';
import { FormEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { getCurrentPatientEncounterAppointment, getPatientEncounterWithTasks, getPatientIncompleteEncounters, insertEncounter, updateTrackerStatus } from 'state/slices/encounter/encounter.actions';
import { getLookups } from 'state/slices/lookups/lookups.reducers';
import { selectOperatoriesAsList } from 'state/slices/lookups/operatories/operatories.selectors';
import { getTaskByEncounter } from 'state/task-management/taskManagement.actions';
import { selectAmendementTasks } from 'state/task-management/taskManagement.slice';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { classicDateOnly } from 'utils/dateOnly';
import EncounterReasons from './encounterReasons/EncounterReasons';
import UserDisplayName from 'components/UserDisplayName';
import { cleanupPatientEncounter, setEncounterAppointment } from 'state/slices/encounter/encounter.slice';
import { encounterState, selectIncompleteEncountersWithPermission, selectPatientEncounter, selectPatientEncounterAppointment } from 'state/slices/encounter/encounter.selectors';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';
import useUserIdentities from 'hooks/store/useUserIdentities';
import { SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';

const encounterStatusLinkText = {
    [EncounterStatus.Created]: 'Resume Encounter',
    [EncounterStatus.ReadyForAttestation]: 'Sign Clinical Note',
    [EncounterStatus.Attested]: 'Complete Encounter',
    [EncounterStatus.Completed]: 'Checked Out',
    [EncounterStatus.AmendRequired]: 'Amend Encounter',
    [EncounterStatus.Billed]: 'Checked Out',
    [EncounterStatus.CorrectionAmend]: 'Correction Amend',
    [EncounterStatus.ConversionImported]: 'Conversion Imported',
    [EncounterStatus.CorrectionsNeeded]: 'Corrections Needed',
    [EncounterStatus.CorrectionsCompleted]: 'Corrections Completed',
    [EncounterStatus.ReBillOnHold]: 'Rebill on Hold',
};

function EncounterTracker(): JSX.Element {
    const dispatch = useDispatch();
    const theme = useTheme();

    const tenantId = useTenantId();
    const encounterId = useEncounterId();
    const patientId = usePatientId();

    const { registerSignalRMessage } = useSignalR();

    const encounter = useSelector(selectPatientEncounter);
    const appointment = useSelector(selectPatientEncounterAppointment);
    const { loadingPatientEncounterAppointment } = useSelector(encounterState);

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.UpdatedPatientAppointmentAllocation, setEncounterAppointment)
        registerSignalRMessage(SignalRMessage.UpdatedEncounter, insertEncounter)
    }, [])


    useEffect(() => {
        if (encounterId && tenantId) {
            dispatch(getCurrentPatientEncounterAppointment({ tenantId, encounterId }))
            if (patientId && (loadingPatientEncounterAppointment === LoadingStatus.Pending || encounterId !== encounter?.id)) dispatch(getPatientEncounterWithTasks({ tenantId, patientId, encounterId }));
            dispatch(getLookups({ tenantId, lookupType: 'Operatories' }));
        }
    }, [tenantId, encounterId, dispatch]);

    return (
        <Stack
            grow
            horizontal
            style={{
                backgroundColor: theme.palette.neutralLighterAlt,
            }}
            verticalAlign="start"
        >
            {encounterId && encounter?.status !== EncounterStatus.Completed && (
                <Stack grow={2} verticalAlign="space-between" style={{ minWidth: 210 }}>
                    <>
                        <EncounterTrackerLocation />
                        <EncounterTrackerStatus />
                        <EncounterReasons />
                    </>
                </Stack>
            )}

            <Stack horizontalAlign="end">
                {encounterId && (
                    <>
                        <EncounterTrackerText encounter={encounter} appointment={appointment} />
                        <ExitEncounter />
                    </>
                )}
                <IncompleteEncounters />
            </Stack>
        </Stack>
    );
}

function ExitEncounter(): JSX.Element {
    const dispatch = useDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();
    return (
        <Stack horizontalAlign="end" grow>
            <Link
                onClick={() => {
                    dispatch(cleanupPatientEncounter());
                }}
                as={RouterLink}
                to={`/${tenantId}/patient/${patientId}`}
            >
                <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 5 }}>
                    <Icon iconName="Cancel" /> <span>Close Encounter</span>
                </Stack>
            </Link>
        </Stack>
    );
}

function EncounterTrackerLink({ encounter }: { encounter?: IPatientEncounter }): JSX.Element | null {
    const { tenantId, patientId } = useParams<RouteParams>();

    const linkText = encounter && encounter.status ? encounterStatusLinkText[encounter.status] : '';

    if (!encounter?.id) return null;

    return (
        <Link as={RouterLink} to={`/${tenantId}/patient/${patientId}/encounter/${encounter.id}`}>
            {linkText}
        </Link>
    );
}

function EncounterTrackerText({ encounter, appointment }: { encounter?: IPatientEncounter; appointment?: IPatientAppointment }) {
    const { data: procedures } = useTenant().procedures;
    const { data: providers } = useTenant().providers;
    const _dateText = convertDashedDateString(appointment?.date);
    const _timeText = appointment?.startTime ? convertFromMilitaryTime(appointment.startTime) : '';
    const dispatch = useDispatch();

    const patientId = usePatientId();
    const { encounterId } = useParams<RouteParams>();
    const tenantId = useTenantId();

    const patientTask = useSelector(selectAmendementTasks);

    useEffect(() => {
        if (encounterId && tenantId && patientId) {
            dispatch(getTaskByEncounter({ tenantId, patientId, encounterId }));
        }
    }, [encounterId, tenantId, patientId, dispatch]);

    const amendmentTask = patientTask.map((task) => {
        const amendmentNotes = task.note;
        const amendmentReferences = task?.references?.amendReason;

        return (
            <Stack key={task.id} tokens={{ childrenGap: 5 }}>
                <Stack horizontal>
                    <strong>Reason</strong>: {amendmentReferences}
                </Stack>
                <Stack horizontal>
                    <strong>Note</strong>: {amendmentNotes}
                </Stack>
            </Stack>
        );
    });

    const _proceduresText =
        appointment && appointment.procedures && appointment.procedures.length
            ? appointment.procedures
                .map((procedure) => {
                    if (procedures && procedure.procedureId && procedures[procedure.procedureId])
                        return procedures[procedure.procedureId]?.code ?? '';
                })
                .join(', ')
            : null;

    const _provider =
        encounter && providers
            ? encounter.treatingProviderId
                ? providers[encounter.treatingProviderId]
                : encounter.hygienistId
                    ? providers[encounter.hygienistId]
                    : null
            : null;

    const encounterText =
        encounter?.status && encounter.status !== EncounterStatus.Completed
            ? `${encounterStatusText[encounter.status]}`
            : 'Complete';

    return (
        <Stack horizontalAlign="end" tokens={{ childrenGap: -3 }}>
            <Text style={{ whiteSpace: 'nowrap' }}>
                {_timeText} - {_dateText} {_proceduresText ? ` | ${_proceduresText}` : ''}
            </Text>
            <Text>
                Treating Provider:{' '}
                <strong>
                    <UserDisplayName userId={_provider?.id} useSuffix />
                </strong>
            </Text>
            {encounter && encounter.status === EncounterStatus.AmendRequired ? (
                <TooltipHost
                    content={amendmentTask}
                    style={{ width: 125 }}
                    delay={TooltipDelay.zero}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                >
                    <Text>
                        Encounter: <strong>{encounterText}</strong>
                    </Text>
                </TooltipHost>
            ) : (
                <Text>
                    Encounter: <strong>{encounterText}</strong>
                </Text>
            )}
        </Stack>
    );
}

function EncounterTrackerLocation(): JSX.Element {

    const tenantId = useTenantId();

    const operatories = useSelector((state) => selectOperatoriesAsList(state, tenantId));
    const appointment = useSelector(selectPatientEncounterAppointment);

    const operatoryId = appointment?.operatoryId;
    const currentOperatory = operatoryId ? find(operatories, ['id', operatoryId])?.displayName : 'N/A';
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
            <Label style={{ height: 20, padding: 0 }}>Location:</Label> <Text>{currentOperatory}</Text>
        </Stack>
    );
}

function EncounterTrackerStatus(): JSX.Element {
    const dispatch = useDispatch();
    const theme = useTheme();

    const tenantId = useTenantId();
    const encounterId = useEncounterId();

    const { loadingPatientEncounterAppointment } = useSelector(encounterState);
    const appointment = useSelector(selectPatientEncounterAppointment);

    const _handleStatusChange = (event?: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option && option.key && appointment && encounterId) {
            dispatch(
                updateTrackerStatus({
                    tenantId,
                    appointmentId: appointment.id,
                    status: option?.key as TrackerStatus,
                    encounterId,
                }),
            );
        }
    };
    const _loading = loadingPatientEncounterAppointment === LoadingStatus.Pending;

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            {_loading ? (
                <Spinner />
            ) : (
                <TrackerStatusDropdown
                    disabled={_loading}
                    label={'Status: '}
                    appointment={appointment}
                    onChange={_handleStatusChange}
                    style={{ display: 'inline', height: 20 }}
                    styles={{
                        title: {
                            backgroundColor: 'transparent',
                            color: theme.semanticColors.link,
                            height: 20,
                            lineHeight: 20,
                            paddingRight: 0,
                            border: 'none',
                            display: 'inline',
                            selectors: {
                                ':hover': {
                                    color: theme.semanticColors.linkHovered,
                                    textDecoration: 'underline',
                                },
                                ':focus': {
                                    border: 'none',
                                },
                                ':active': {
                                    border: 'none',
                                },
                            },
                        },
                        dropdown: {
                            selectors: {
                                ':focus .ms-Field.Dropdown-title': {},
                            },
                        },
                        caretDownWrapper: {
                            height: 15,
                            /* line-height: 20px; */
                            paddingTop: 1,
                            position: 'absolute',
                            right: -18,
                            top: 2,
                            cursor: 'pointer',
                            width: 15,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 3,
                        },
                        caretDown: {
                            color: theme.semanticColors.link,
                        },
                        label: {
                            height: 20,
                            padding: 0,
                        },
                        root: {
                            height: 20,
                        },
                    }}
                />
            )}
        </Stack>
    );
}

function IncompleteEncounters(): JSX.Element | null {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const { encounterId, patientId, tenantId } = useParams<RouteParams>();
    const incompleteEncounters = useSelector(selectIncompleteEncountersWithPermission);
    const { userIdentitiesData, getMissingUserIdentities } = useUserIdentities();

    useEffect(() => {
        if (patientId) dispatch(getPatientIncompleteEncounters({ tenantId, patientId }));
    }, [tenantId, patientId]);

    const hasMultipleIncompleteEncounters = incompleteEncounters && incompleteEncounters?.length > 0;
    if (encounterId || !hasMultipleIncompleteEncounters) return null;
    if (incompleteEncounters?.length === 1) {
        const encounter = incompleteEncounters[0].encounter;
        const appointment = incompleteEncounters[0].appointment;
        return (
            <Stack horizontalAlign="end">
                <EncounterTrackerText appointment={appointment} encounter={encounter} />
                <EncounterTrackerLink encounter={encounter} />
            </Stack>
        );
    }
    const groupedEncounters = groupBy(
        orderBy(incompleteEncounters, ['encounter.encounterDate'], ['asc']),
        ({ encounter }) => encounter?.encounterDate,
    );
    const newEncounterOptions: IDropdownOption[] = map(groupedEncounters, (items, date) => {
        if (!items.length) return [];

        const label = date ? classicDateOnly(date) : '';
        const labelOption: IDropdownOption = { key: label, text: label, itemType: DropdownMenuItemType.Header };
        const appointmentOptions: IDropdownOption[] = map(items, ({ encounter, appointment }) => {
            const key = encounter?.id as string;
            const time = appointment?.startTime ? convertFromMilitaryTime(appointment.startTime) : '';
            // Fetch user identity data if not already present
            if (appointment?.treatingProviderId && !userIdentitiesData[appointment?.treatingProviderId])
                getMissingUserIdentities([appointment.treatingProviderId]);
            const user = appointment?.treatingProviderId ? userIdentitiesData[appointment.treatingProviderId] : undefined;

            const userName = user
                ? user.professionalSuffix
                    ? `${user.lastName} ${user.professionalSuffix}, ${user.firstName}`
                    : user.displayName ?? 'N/A'
                : 'N/A';

            return {
                key,
                text: `${time} - ${appointment?.encounterReason} - ${userName}`,
            };
        });
        const dividerOption: IDropdownOption = { key: `${label}-divider`, text: '', itemType: DropdownMenuItemType.Divider };
        return [labelOption, ...appointmentOptions, dividerOption];
    }).flat();

    const _handleSelectEncounter = (event?: FormEvent<HTMLDivElement>, option?: IDropdownOption<string>) => {
        if (option && option.key) {
            push(`/${tenantId}/patient/${patientId}/encounter/${option.key}`);
        }
    };
    return (
        <Dropdown
            label="Go to encounter:"
            placeholder="Select incomplete encounter"
            options={newEncounterOptions}
            dropdownWidth="auto"
            onChange={_handleSelectEncounter}
        />
    );
}

export default EncounterTracker;
