import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { ChartProcedurePreAuth, ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import { ToothArea } from 'api/models/tooth-area';
import axios, { AxiosResponse } from 'axios';
import { AppThunk, RootState } from 'state/store';
import { applyActionToTeeth, deselectTeeth, ProcedureActionType, setChartingPipelineError } from '../chart/chart.slice';
import ChartingProceduresPipeline from '../chartingProcedures.pipeline';
import ProcedureApplicableAreasPipeline from '../procedureApplicableAreasPipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { IChartAction } from '../chartActionsList.pipeline';
import ProcedureDiagnosisPipeline from '../procedureDiagnosisPipeline/procedureDiagnosisPipeline.pipeline';
import { IProcedure } from 'api/models/procedure.model';
import { updateChartTreatmentPlansProceduresStatuses } from '../treatmentPlans/treatmentPlans.actions';
import { IPipelineError } from '../pipeline';
import { getTeethReference } from '../dentition/dentition.selectors';

export const getChartProcedures = createAsyncThunk<
    IChartProcedure[],
    {
        tenantId: string;
        patientId: string;
    }
>('getChartProcedures', async ({ tenantId, patientId }) => {
    const result = await dentalApi.getChartProcedures(tenantId, patientId);
    return result.data;
});

export const getChartProcedureById = createAsyncThunk<
    IChartProcedure,
    {
        tenantId: string;
        patientId: string;
        procedureId: string;
    }
>('getChartProcedureById', async ({ tenantId, patientId, procedureId }) => {
    const result = await dentalApi.getChartProcedureById(tenantId, patientId, procedureId);
    return result.data;
});

export const createChartProcedure = createAsyncThunk<
    IChartProcedure,
    {
        tenantId: string;
        patientId: string;
        procedure: IChartProcedure;
    }
>('createChartProcedure', async ({ tenantId, patientId, procedure }) => {
    const result = await dentalApi.createChartProcedure(tenantId, patientId, procedure);
    return result.data;
});

export const createChartProcedures = createAsyncThunk<
    IChartProcedure[],
    {
        tenantId: string;
        patientId: string;
        procedures: IChartProcedure[];
    }
>('createChartProcedures', async ({ tenantId, patientId, procedures }, { dispatch }) => {
    dispatch(deselectTeeth());
    const createResponses = procedures.map((p) => dentalApi.createChartProcedure(tenantId, patientId, p));
    const results = await axios.all(createResponses);
    return results.map((r) => r.data);
});

export const updateChartProcedure = createAsyncThunk<
    IChartProcedure,
    {
        tenantId: string;
        patientId: string;
        procedure: IChartProcedure;
    }
>('updateChartProcedure', async ({ tenantId, patientId, procedure }) => {
    const result = await dentalApi.updateChartProcedure(tenantId, patientId, procedure);
    return result.data;
});

//A chart procedure can exist in multiple treatment plans for an encounter.

//Firstly, we need to update the status of the procedures that are phased in the treatment plans it exists in.

//If the procedure is not pending:
//1. Gather list of non-deleted treatment plans that contain the procedure.
//2. Update the status of the procedure on those plans.
//3. Check if all the procedures with the same phaseId as the proc we are looking are not pending.
//   - If true, we can set the phase status to completed.
//4. Save the TP.
export const updateChartProcedures = createAsyncThunk<
    IChartProcedure[],
    {
        tenantId: string;
        patientId: string;
        procedures: IChartProcedure[];
        updateTreatmentPlanStatuses?: boolean;
    },
    { dispatch: ThunkDispatch<RootState, unknown, AnyAction> }
>('updateChartProcedures', async ({ tenantId, patientId, procedures, updateTreatmentPlanStatuses }, { dispatch }) => {
    const proceduresToUpdate = procedures.map((p) => {
        const newProcedure = {
            ...p,
            onCompletedDate: isStatusCompleted(p) && !p.onCompletedDate ? new Date().toISOString() : undefined,
        };
        return newProcedure;
    });
    if (updateTreatmentPlanStatuses) dispatch(updateChartTreatmentPlansProceduresStatuses(tenantId, patientId, procedures));

    const requests = proceduresToUpdate.map((p) => dentalApi.updateChartProcedure(tenantId, patientId, p));
    const result = await axios.all(requests);
    return result.map((response) => response.data);
});

function isStatusCompleted(item: IChartProcedure) {
    return item.status === ChartProcedureStatus.Completed;
}

export const updateProcedureStatuses =
    (
        tenantId: string,
        patientId: string,
        chartActions: IChartAction | IChartAction[] | IChartProcedure | IChartProcedure[],
        status: ChartProcedureStatus,
        encounterId?: string,
        updateTreatmentPlanStatuses?: boolean,
    ): AppThunk<void> =>
    (dispatch, getState): void => {
        const chartProcedures = getState().charting.procedures.data;

        if (chartActions) {
            if (Array.isArray(chartActions)) {
                const chartProceduresFromActions = chartActions
                    .map((chartAction) => chartProcedures.find((chartProc) => chartProc.id === chartAction.id))
                    .filter((chartProcs) => chartProcs !== undefined) as IChartProcedure[];

                if (chartProceduresFromActions && chartProceduresFromActions.length) {
                    const procedures: IChartProcedure[] = chartProceduresFromActions.map((p) => {
                        const newProcedure = {
                            ...p,
                            status: status as keyof typeof ChartProcedureStatus,
                            encounterId: encounterId ? encounterId : p.encounterId,
                            statusChangedDate: status !== 'Pending' ? new Date().toISOString() : undefined,
                        };

                        return newProcedure;
                    });
                    dispatch(updateChartProcedures({ tenantId, patientId, procedures, updateTreatmentPlanStatuses }));
                }
            } else {
                const chartProcedure = chartProcedures.find((proc) => proc.id === chartActions.id);
                if (chartProcedure) {
                    const procedures: IChartProcedure[] = [
                        {
                            ...chartProcedure,
                            status,
                            encounterId: encounterId ? encounterId : chartProcedure.encounterId,
                            statusChangedDate: status !== 'Pending' ? new Date().toISOString() : undefined,
                        },
                    ];
                    dispatch(updateChartProcedures({ tenantId, patientId, procedures, updateTreatmentPlanStatuses }));
                }
            }
        }
    };

export const updateProcedurePreAuths =
    (
        tenantId: string,
        patientId: string,
        chartActions: IChartAction | IChartAction[],
        preAuthorization: ChartProcedurePreAuth,
    ): AppThunk<void> =>
    (dispatch, getState): void => {
        const chartProcedures = getState().charting.procedures.data;
        if (chartActions) {
            if (Array.isArray(chartActions)) {
                const chartProceduresFromActions = chartActions
                    .map((chartAction) => chartProcedures.find((chartProc) => chartProc.id === chartAction.id))
                    .filter((chartProcs) => chartProcs !== undefined) as IChartProcedure[];

                if (chartProceduresFromActions && chartProceduresFromActions.length) {
                    const procedures: IChartProcedure[] = chartProceduresFromActions.map((p) => ({
                        ...p,
                        preAuthorization,
                    }));
                    dispatch(updateChartProcedures({ tenantId, patientId, procedures }));
                }
            } else {
                const chartProcedure = chartProcedures.find((proc) => proc.id === chartActions.id);
                if (chartProcedure) {
                    const procedures = [{ ...chartProcedure, preAuthorization }];
                    dispatch(updateChartProcedures({ tenantId, patientId, procedures }));
                }
            }
        }
    };

export const deleteChartProceduresAsync = createAsyncThunk<
    IChartProcedure[],
    {
        procedureRequests: Promise<AxiosResponse<IChartProcedure>>[];
    }
>('deleteChartProceduresAsync', async ({ procedureRequests }) => {
    const proceduresRes = await axios.all(procedureRequests);
    const proceduresData = proceduresRes.map((account) => (account as AxiosResponse<IChartProcedure>).data);
    return proceduresData;
});

export const deleteChartProcedures =
    (tenantId: string, patientId: string, chartProcedures: IChartProcedure[]) =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): void => {
        if (chartProcedures.length) {
            const procedures = chartProcedures.map((procedure) =>
                dentalApi.updateChartProcedure(tenantId, patientId, { ...procedure, isDeleted: true, status: 'Removed' }),
            );
            dispatch(deleteChartProceduresAsync({ procedureRequests: procedures }));
        }
    };

export interface IAddProceduresParams {
    tenantId: string;
    patientId: string;
    procedureIds: string[];
    toothAreas?: (keyof typeof ToothArea)[];
    encounterId?: string;
    typeOverride?: ProcedureActionType;
    groupToothIds?: boolean;
    addAutoApplyDiagnoses?: boolean;
    status?: ChartProcedureStatus;
    elementId?: string;
}

/**
 * Adds list of procedures (given ids) to chart procedures
 *
 * @param {string} tenantId
 * @param {string} patientId
 * @param {string[]} procedureIds
 * @param {string} encounterId
 * @param {(keyof typeof ToothArea)[]} toothAreas
 */
export const addProcedures =
    ({ tenantId, patientId, procedureIds, toothAreas, encounterId, typeOverride, status, elementId }: IAddProceduresParams) =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState): Promise<void> => {
        const teethReference = getTeethReference(getState());
        const proceduresData = getState().tenant.procedures.data;
        const diagnosisData = getState().tenant.diagnoses.data;
        const conditionsData = getState().tenant.conditions.data;
        const type = typeOverride ? typeOverride : getState().tenant.quickActions.selectedQuickActionType;
        const selectedTeeth = getState().charting.ui.selectedTeeth;
        const currentChartProcedures = getState().charting.procedures.data.filter((item) => !item.isDeleted);
        const currentConditions = getState().charting.conditions.data.filter((item) => !item.isDeleted);
        const encounterAppointment = getState().encounter.patientEncounterAppointment;
        if (proceduresData && conditionsData && selectedTeeth) {
            const procedures = procedureIds.map((id) => proceduresData[id]) as IProcedure[];
            const treatingProviderId = encounterAppointment?.treatingProviderId
                ? encounterAppointment.treatingProviderId
                : undefined;

            const proceduresWithDiagnosis = new ProcedureDiagnosisPipeline({
                encounterId,
                diagnosisData,
                proceduresData,
                newProcedures: procedures,
                chartProceduresList: currentChartProcedures,
            }).getItems;

            new ChartingProceduresPipeline({
                newProcedures: procedures,
                currentChartProcedures,
                type,
                selectedTeeth,
                encounterId,
                proceduresWithDiagnosis,
                treatingProviderId,
                status,
            }).next((chartProcedures) => {
                new ProcedureApplicableAreasPipeline({
                    procedures,
                    chartProcedures,
                    toothAreas,
                }).next((chartProcedures) => {
                    new ProcedureConflictRulesPipeline({
                        newProcedures: procedures,
                        chartProcedures,
                        currentProcedures: currentChartProcedures,
                        selectedTeeth,
                        procedureData: proceduresData,
                        conditionsData,
                        currentConditions,
                        teethReference,
                        patientEncounterId: encounterId,
                    }).next((chartProcedures, errors) => {
                        if (elementId && errors?.length) {
                            dispatch(setChartingPipelineError({ buttonId: elementId, errors }));
                        }
                        if (chartProcedures.length) {
                            dispatch(applyActionToTeeth(chartProcedures));
                            dispatch(createChartProcedures({ tenantId, patientId, procedures: chartProcedures }));
                            dispatch(deselectTeeth());
                        }
                    });
                });
            });
        }
    };
