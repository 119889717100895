import { IDropdownOption, INavLink, SelectableOptionMenuItemType } from '@fluentui/react';
import { ISearchComboBoxOption } from 'components/Field/SearchComboField';
import { flatten, map, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { selectReportingProviderOptions } from 'state/slices/tenant/providers.slice';
import { selectTenantPayersWithDisplayName } from 'state/slices/tenant/tenant-payers.slice';
import { RootState } from 'state/store';
import { selectLocationsOfCareFromReporting } from '../reporting.selectors';

export const selectReportingGroupsState = (state: RootState) => state.reporting.reportingGroups;

// Group
export const selectSelectedReportingGroup = createSelector(selectReportingGroupsState, (state) => state.selectedGroup);
export const selectReportingGroupLookup = createSelector(selectReportingGroupsState, (state) => state.groups ?? {});
export const reportingGroupList = createSelector(selectReportingGroupsState, (state) => {
    return map(state.groups);
});

export const reportingGroupOptions = createSelector(reportingGroupList, (groups) => {
    const options: IDropdownOption[] = [
        {
            itemType: SelectableOptionMenuItemType.Header,
            text: 'Reporting Groups',
            key: 'header1',
        },
        {
            itemType: SelectableOptionMenuItemType.Divider,
            text: '',
            key: 'divider1',
        },
        ...groups.map((group) => ({
            key: `${group?.type} - ${group?.displayName ?? ''}`,
            text: ` ${group?.type} - ${group?.displayName ?? ''}`,
        })),
        {
            itemType: SelectableOptionMenuItemType.Divider,
            text: '',
            key: 'divider2',
        },
        {
            itemType: SelectableOptionMenuItemType.Header,
            text: 'Options',
            key: 'header2',
        },
        {
            itemType: SelectableOptionMenuItemType.Divider,
            text: '',
            key: 'divider3',
        },
    ];

    return options;
});

export const loadingReportingGroups = createSelector(selectReportingGroupsState, (state) => state.loadingGroups);
export const loadingReportingSelectedGroup = createSelector(selectReportingGroupsState, (state) => state.loadingGroup);
export const isModifiedReportingSelectedGroup = createSelector(
    selectReportingGroupsState,
    (state) => state.selectedReportingGroupModified,
);

export const navReportingGroupList = createSelector(selectReportingGroupsState, (groups) => {
    if (groups) {
        return sortBy(
            map(groups.groups)
                .filter((group) => !group?.isDeleted)
                .map((group) => {
                    return {
                        key: group?.id,
                        id: group?.id,
                        name: group?.displayName,
                        url: group?.id,
                    } as INavLink;
                }),
            ['name'],
            ['desc'],
        );
    }
    return [];
});

// Category
export const reportingCategories = createSelector(selectReportingGroupsState, (state) => state.categories ?? {});
export const reportingCategoriesLoading = createSelector(selectReportingGroupsState, (state) => state.loadingCategories);
export const reportingCategoriesList = createSelector(selectReportingGroupsState, (state) => {
    return map(state.categories).filter((category) => !category?.isDeleted);
});
export const reportingSelectedCategoryLoading = createSelector(selectReportingGroupsState, (state) => state.loadingCategory);

export const selectSelectedReportingCategory = createSelector(selectReportingGroupsState, (state) => state?.selectedCategory);

export const selectSelectedReportingCategoryItems = createSelector(
    selectSelectedReportingCategory,
    (category) => category?.items ?? [],
);

/**
 * category[] that doesn't have the currently selected category._
 *
 * Then map all the items from those categories._
 * Discover if the array contains the option that we're looking at when filtering.
 */

export const selectedReportingGroupCategoriesList = createSelector(
    [selectSelectedReportingGroup, reportingCategories],
    (group, categoryLookup) => {
        if (group) {
            return group.reportingCategories?.map((id) => categoryLookup[id]);
        }
        return [];
    },
);

export const selectedReportingGroupCategoriesItems = createSelector(
    [selectedReportingGroupCategoriesList, selectSelectedReportingCategory],
    (category, selectedCategory) => {
        return category
            ?.filter((c) => c?.id !== selectedCategory?.id)
            ?.map((c) => (c ? c.items ?? [] : []))
            .flat();
    },
);

export const selectLocationsOfCareForReportingGroups = createSelector(
    [selectLocationsOfCareFromReporting, selectedReportingGroupCategoriesItems],
    (locs, selectedGroupOptions) => {
        return locs.filter((loc) => !selectedGroupOptions?.includes(loc.id));
    },
);
export const selectProviderForReportingGroup = createSelector(
    [selectReportingProviderOptions, selectedReportingGroupCategoriesItems],
    (providers, selectedGroupOptions) => {
        return providers.filter((provider) => !selectedGroupOptions?.includes(provider.key as string));
    },
);
export const selectGroupedPayersForReportingGroups = createSelector(
    [selectTenantPayersWithDisplayName, selectedReportingGroupCategoriesItems],
    (payers, selectedGroupOptions) => {
        const nonSelectedPlayer = payers.filter((payer) => !selectedGroupOptions?.includes(payer?.id as string));

        const quickPicksOptions: ISearchComboBoxOption[] = nonSelectedPlayer
            .filter((payer) => payer?.isQuickPick && payer?.id)
            .map((payer) => ({
                key: payer?.id ?? '',
                text: payer?.name ? `(${payer.payerId}) ${payer?.name}` : 'Unknown payer',
                groupName: 'Quick Picks',
            }));
        const options: ISearchComboBoxOption[] = nonSelectedPlayer
            .filter((payer) => !payer?.isQuickPick)
            .map((payer) => ({ key: payer?.id ?? '', text: payer?.name ? `(${payer.payerId}) ${payer.name}` : 'Unknown payer' }));
        return [...quickPicksOptions, ...options];
    },
);
