import { SelectionMode } from '@fluentui/react';
import { WorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import { RecentWorkListItem } from 'utils/appLocalStorage';
import { getBaseEncounterRecentItemActions, readyToRebillActions } from './EncounterWorkLists/EncounterWorkListRecentActions';
import { getRecentlyViewedAppointmentItemFromData } from './MissingSlipsWorkLists/MissingSlips';
import {
    amendRequireEncounters,
    outstandingCheckInAppointmentsActions,
    outstandingCheckoutAppointments,
} from './MissingSlipsWorkLists/MissingSlipsRecentActions';
import { WorkListRecentlyViewedAction } from './WorkListRecentItems';
import { getRecentEncounterViewItemToAdd } from './EncounterWorkLists/EncounterWorkList';
import { WorkListColumnComponents, WorkListFilterComponents, WorkListLayerComponents } from './WorkListComponentLookup';
import {
    getRecentlyViewedPredeterminationItemFromData,
    predeterminationRecentItemActions,
} from './Predeterminations/PredeterminationRecentItemActions';
import { classicDateOnly } from 'utils/dateOnly';

export type WorkListDetailsData<C = any | undefined> = {
    columns?: WorkListColumnComponents[];
    expandedRowColumns?: WorkListColumnComponents[];
    selectionMode?: SelectionMode;
    readOnly?: boolean;

    hasRecentItems?: boolean;
    hasPageSelector?: boolean;

    recentlyViewedActions?: WorkListRecentlyViewedAction[];
    getRecentlyViewedItemFromData?: (item: Partial<C>) => RecentWorkListItem;
    filterComponents?: WorkListFilterComponents[];
    filterInitialValues?: Record<string, unknown>;
    layerComponents?: WorkListLayerComponents[];

    sortColumns?: string[];
    initialSortDirection?: ('asc' | 'desc')[];
};

type WorkListDetailsDataLookup = Record<WorkList, WorkListDetailsData>;

const baseEncounterWorkListLayerComponents: WorkListLayerComponents[] = ['EncounterNoteModal', 'VisitSummaryPanel'];
const editEncountersWorkListLayerComponents: WorkListLayerComponents[] = [
    ...baseEncounterWorkListLayerComponents,
    'EditEncountersConfirmationModal',
    'VoidProcedureConfirmation',
];

const baseEncounterWorkListFilters: WorkListFilterComponents[] = [
    'DOSThroughFilter',
    'LocationOfCareFilter',
    'ProvidersFilter',
    'PrimaryPayerSourceFilter',
    'FindPatientFilter',
];
const openBilledEncountersWorkListFilters: WorkListFilterComponents[] = [
    'DOSFromEncounterWorkListFilter',
    ...baseEncounterWorkListFilters,
];
const denialEncounterWorkListFilters: WorkListFilterComponents[] = [...baseEncounterWorkListFilters, 'ReasonCodesFilter'];
const missingSlipsWorkListFilters: WorkListFilterComponents[] = [
    'DOSThroughFilter',
    'LocationOfCareFilter',
    'ProvidersFilter',
    'FindPatientFilter',
];

const columnsWithCorrespondence: WorkListColumnComponents[] = [
    'encounterCorrespondence',
    'visitSummary',
    'encounterDate',
    'lastUpdated',
    'patientNameGlobal',
    'attestingProvider',
    'locationOfCareName',
    'primaryPayerSource',
    'primaryPayerId',
    'secondaryPayerSource',
    'secondaryPayerId',
];

const encounterWorkListReadyToReviewColumns: WorkListColumnComponents[] = [
    'visitSummary',
    'encounterDate',
    'patientNameWorkList',
    'billingProviderName',
    'attestingProviderName',
    'locationOfCareName',
    'primaryPayerSource',
    'primaryPayerId',
    'secondaryPayerSource',
    'secondaryPayerId',
    'onHold',
    'billingNote',
];

const encounterWorkListReadyToReviewOnHoldColumns: WorkListColumnComponents[] = [
    'visitSummary',
    'recalculateEncounterButton',
    'encounterDate',
    'patientNameWorkList',
    'billingProviderName',
    'attestingProviderName',
    'locationOfCareName',
    'primaryPayerSource',
    'primaryPayerId',
    'secondaryPayerSource',
    'secondaryPayerId',
    'onHold',
    'billingNote',
];

const columnsWithCorrespondenceAndRecalculate: WorkListColumnComponents[] = [
    'encounterCorrespondence',
    'visitSummary',
    'recalculateEncounterButton',
    'encounterDate',
    'lastUpdated',
    'patientNameGlobal',
    'attestingProvider',
    'locationOfCareName',
    'primaryPayerSource',
    'primaryPayerId',
    'secondaryPayerSource',
    'secondaryPayerId',
];

const encounterWorkListColumnsWithoutCorrespondence: WorkListColumnComponents[] = [
    'visitSummary',
    'encounterDate',
    'patientNameGlobal',
    'amendingProvider',
    'locationOfCareName',
    'primaryPayerSource',
    'primaryPayerId',
];

const billingProceduresEditEncounterWorkListColumns: WorkListColumnComponents[] = [
    'voidProcedure',
    'authNumber',
    'procedureCode',
    'procedureDescription',
    'diagnosisCodes',
    'completingProvider',
    'tooth',
    'toothArea',
    'totalFee',
    'insuranceFee',
    'patientFee',
];

const billingProcedureEditDenialsEncounterWorkListColumns: WorkListColumnComponents[] = [
    'voidProcedure',
    'authNumber',
    'procedureCode',
    'procedureDescription',
    'diagnosisCodes',
    'completingProvider',
    'tooth',
    'toothArea',
    'reasonCodes',
    'totalFee',
    'insuranceFee',
    'patientFee',
];
const billingProcedureDenialsEncounterWorkListColumns: WorkListColumnComponents[] = [
    'authNumber',
    'procedureCode',
    'procedureDescription',
    'diagnosisCodes',
    'completingProvider',
    'tooth',
    'toothArea',
    'reasonCodes',
    'totalFee',
    'insuranceFee',
    'patientFee',
];

const billingProcedureEncounterWorkListColumns: WorkListColumnComponents[] = [
    'authNumber',
    'procedureCode',
    'procedureDescription',
    'diagnosisCodes',
    'completingProvider',
    'tooth',
    'toothArea',
    'totalFee',
    'insuranceFee',
    'patientFee',
];

const openBilledWorkListColumns: WorkListColumnComponents[] = [
    'encounterCorrespondence',
    'visitSummary',
    'encounterDate',
    'patientNameWorkList',
    'attestingProvider',
    'locationOfCareName',
    'primaryPayerSource',
    'primaryPayerId',
    'secondaryPayerSource',
    'secondaryPayerId',
];

const workListDetailsLookup: WorkListDetailsDataLookup = {
    EditEncounters: {
        columns: columnsWithCorrespondenceAndRecalculate,
        expandedRowColumns: billingProcedureEditDenialsEncounterWorkListColumns,
        selectionMode: SelectionMode.none,
        filterComponents: [...denialEncounterWorkListFilters, 'RCMFilter'],
        recentlyViewedActions: getBaseEncounterRecentItemActions({ isEditEncounterWorklist: true }),
        layerComponents: editEncountersWorkListLayerComponents,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    // EditEncountersPatient: {
    //     columns: patientColumnsAndRecalculate,
    //     expandedRowColumns: billingProceduresEditEncounterWorkListColumns,
    //     selectionMode: SelectionMode.none,
    //     filterComponents: baseEncounterWorkListFilters,
    //     recentlyViewedActions: getBaseEncounterRecentItemActions({ isEditEncounterWorklist: true }),
    //     layerComponents: editEncountersWorkListLayerComponents,
    //     getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    // },
    EditEncountersCorrectionAmend: {
        columns: columnsWithCorrespondence,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        selectionMode: SelectionMode.none,
        readOnly: true,
        filterComponents: baseEncounterWorkListFilters,
        recentlyViewedActions: getBaseEncounterRecentItemActions({ readOnly: true, isEditEncounterWorklist: true }),
        layerComponents: editEncountersWorkListLayerComponents,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    EditEncountersDenials: {
        columns: columnsWithCorrespondenceAndRecalculate,
        expandedRowColumns: billingProcedureEditDenialsEncounterWorkListColumns,
        selectionMode: SelectionMode.none,
        filterComponents: denialEncounterWorkListFilters,
        recentlyViewedActions: getBaseEncounterRecentItemActions({ isEditEncounterWorklist: true }),
        layerComponents: editEncountersWorkListLayerComponents,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    Denials: {
        columns: columnsWithCorrespondence,
        expandedRowColumns: billingProcedureDenialsEncounterWorkListColumns,
        selectionMode: SelectionMode.none,
        filterComponents: denialEncounterWorkListFilters,
        recentlyViewedActions: getBaseEncounterRecentItemActions({ readOnly: true }),
        layerComponents: ['ReadOnlyVisitSummaryPanel', 'EncounterNoteModal'],
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    AmendRequireEncounters: {
        columns: [...encounterWorkListColumnsWithoutCorrespondence, 'amendReason', 'amendNotes'],
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        readOnly: true,
        selectionMode: SelectionMode.none,
        recentlyViewedActions: amendRequireEncounters,
        filterComponents: baseEncounterWorkListFilters,
        layerComponents: baseEncounterWorkListLayerComponents,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    BilledInsurance: {
        columns: openBilledWorkListColumns,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        selectionMode: SelectionMode.none,
        recentlyViewedActions: getBaseEncounterRecentItemActions({}),
        filterComponents: openBilledEncountersWorkListFilters,
        filterInitialValues: { dateOfServiceThrough: classicDateOnly(new Date().toISOString()) },
        layerComponents: [...baseEncounterWorkListLayerComponents, 'EditPatientPanel'],
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    BilledInsuranceCorrectionNeeded: {
        readOnly: true,
        columns: columnsWithCorrespondence,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        selectionMode: SelectionMode.none,
        recentlyViewedActions: getBaseEncounterRecentItemActions({ readOnly: true }),
        filterComponents: baseEncounterWorkListFilters,
        layerComponents: baseEncounterWorkListLayerComponents,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    BilledPatient: {
        columns: openBilledWorkListColumns,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        selectionMode: SelectionMode.none,
        filterComponents: openBilledEncountersWorkListFilters,
        filterInitialValues: { dateOfServiceThrough: classicDateOnly(new Date().toISOString()) },
        layerComponents: [...baseEncounterWorkListLayerComponents, 'EditPatientPanel'],
        recentlyViewedActions: getBaseEncounterRecentItemActions({}),
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    BilledPatientCorrectionNeeded: {
        readOnly: true,
        columns: [
            'encounterCorrespondence',
            'visitSummary',
            'encounterDate',
            'lastUpdated',
            'patientNameGlobal',
            'attestingProvider',
            'locationOfCareName',
            'primaryPayerSource',
            'primaryPayerId',
            'secondaryPayerSource',
            'secondaryPayerId',
        ],
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        selectionMode: SelectionMode.none,
        filterComponents: baseEncounterWorkListFilters,
        recentlyViewedActions: getBaseEncounterRecentItemActions({ readOnly: true }),
        layerComponents: baseEncounterWorkListLayerComponents,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    ReadyToRebill: {
        selectionMode: SelectionMode.multiple,
        columns: columnsWithCorrespondence,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        filterComponents: baseEncounterWorkListFilters,
        recentlyViewedActions: getBaseEncounterRecentItemActions({ readOnly: true }),
        layerComponents: baseEncounterWorkListLayerComponents,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    ReadyToRebillOnHold: {
        selectionMode: SelectionMode.none,
        columns: columnsWithCorrespondence,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        recentlyViewedActions: getBaseEncounterRecentItemActions({}),
        filterComponents: baseEncounterWorkListFilters,
        layerComponents: baseEncounterWorkListLayerComponents,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    ReadyToReview: {
        columns: encounterWorkListReadyToReviewColumns,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        selectionMode: SelectionMode.multiple,
        filterComponents: baseEncounterWorkListFilters,
        layerComponents: [...baseEncounterWorkListLayerComponents, 'EditPatientPanel'],
        recentlyViewedActions: readyToRebillActions,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    ReadyToReviewOnHold: {
        selectionMode: SelectionMode.multiple,
        columns: encounterWorkListReadyToReviewOnHoldColumns,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        layerComponents: [...baseEncounterWorkListLayerComponents, 'EditPatientPanel'],
        filterComponents: baseEncounterWorkListFilters,
        recentlyViewedActions: readyToRebillActions,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    BilledInsuranceCorrectionAmend: {
        selectionMode: SelectionMode.none,
        readOnly: true,
        columns: columnsWithCorrespondence,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        filterComponents: baseEncounterWorkListFilters,
        recentlyViewedActions: getBaseEncounterRecentItemActions({ readOnly: true }),
        layerComponents: baseEncounterWorkListLayerComponents,
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    BilledPatientCorrectionAmend: {
        selectionMode: SelectionMode.none,
        readOnly: true,
        columns: columnsWithCorrespondence,
        expandedRowColumns: billingProcedureEncounterWorkListColumns,
        filterComponents: baseEncounterWorkListFilters,
        recentlyViewedActions: getBaseEncounterRecentItemActions({ readOnly: true }),
        layerComponents: ['VisitSummaryPanel', 'EncounterNoteModal'],
        getRecentlyViewedItemFromData: getRecentEncounterViewItemToAdd,
    },
    OutstandingCheckInAppointments: {
        selectionMode: SelectionMode.none,
        columns: [
            'outstandingCheckinActions',
            'appointmentDateLink',
            'appointmentTrackerStatus',
            'patientNameWorkList',
            'patientPrimaryPhone',
            'locationOfCareName',
            'treatingProvider',
            'primaryPayerSource',
            'appointmentConfirmationStatus',
            'reasonForEncounter',
        ],
        recentlyViewedActions: outstandingCheckInAppointmentsActions,
        filterComponents: missingSlipsWorkListFilters,
        layerComponents: ['CheckInPanel', 'CancelAppointmentModal', 'AppointmentOverview', 'EditPatientPanel'],
        getRecentlyViewedItemFromData: getRecentlyViewedAppointmentItemFromData,
    },
    OutstandingCheckoutAppointments: {
        selectionMode: SelectionMode.none,
        columns: [
            'appointmentDateLink',
            'appointmentTrackerStatus',
            'visitSummary',
            'patientNameWorkList',
            'locationOfCareName',
            'treatingProvider',
            'attendingProvider',
            'primaryPayerSource',
            'encounterStatus',
            'reasonForEncounter',
        ],
        recentlyViewedActions: outstandingCheckoutAppointments,
        filterComponents: [
            'DOSThroughFilter',
            'TrackerStatusFilter',
            'LocationOfCareFilter',
            'ProvidersFilter',
            'FindPatientFilter',
        ],
        layerComponents: [
            'CheckoutPanel',
            'CheckInPanel',
            'AppointmentOverview',
            'ReadOnlyVisitSummaryPanel',
            'CancelAppointmentModal',
            'EditPatientPanel',
        ],
        getRecentlyViewedItemFromData: getRecentlyViewedAppointmentItemFromData,
    },
    AwaitingAttestation: {
        selectionMode: SelectionMode.none,
        columns: [
            'appointmentDateLink',
            'patientNameGlobal',
            'visitSummary',
            'locationOfCareName',
            'treatingProvider',
            'attendingProvider',
            'billingProvider',
            'reasonForEncounter',
            'appointmentTrackerStatusReadOnly',
        ],
        recentlyViewedActions: outstandingCheckoutAppointments,
        filterComponents: missingSlipsWorkListFilters,
        layerComponents: ['AppointmentOverview', 'EditPatientPanel', 'VisitSummaryPanel'],
        getRecentlyViewedItemFromData: getRecentlyViewedAppointmentItemFromData,
    },
    Predeterminations: {
        selectionMode: SelectionMode.none,
        columns: [
            'editAuthorzation',
            'signedDate',
            'patientNameGlobal',
            'procedureCode',
            'procedureDescription',
            'primaryPayerSource',
            'tooth',
            'preAuth',
            'preAuthCode',
        ],
        layerComponents: ['EditPredetermination'],
        recentlyViewedActions: predeterminationRecentItemActions,
        filterComponents: ['FindPatientFilter', 'SignedDateRangeFilter', 'PreAuthStatusFilter'],
        getRecentlyViewedItemFromData: getRecentlyViewedPredeterminationItemFromData,
    },
};

export default workListDetailsLookup;
