import { map } from 'lodash';
import { RootState } from 'state/store';

export default function DDS({ state }: { state: RootState }): JSX.Element | null {
    const ddsId = state.encounter.patientEncounterAppointment?.treatingProviderId;
    const ddss = map(state.tenant.providers.data, (provider) => provider).filter(
        (provider) => provider?.isTreatingProvider || provider?.isAttestingHygienist,
    );
    const dds = ddss.find((provider) => provider?.id === ddsId);

    if (ddsId) {
        return (
            <>
                <h3>Treating Provider</h3>
                <ul>
                    <li>
                        {dds?.firstName} {dds?.lastName}
                        {dds?.suffix ? `, ${dds.suffix}` : ''}
                    </li>
                </ul>
            </>
        );
    } else {
        return null;
    }
}
