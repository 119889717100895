import { PayloadAction } from '@reduxjs/toolkit';
import {
    IEncounterDetailsReportQuery,
    IEncounterStatusReportQuery,
    IEncounterSummaryReportQuery,
} from 'api/models/encounter-reports.model';
import { IReportingState } from '../reporting.state';

export const encounterDetailSummaryReducers = {
    setEncounterDetailProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IEncounterDetailsReportQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IEncounterDetailsReportQuery)[path] as string | number | undefined) = value;
    },
    toggleEncounterDetailsId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IEncounterDetailsReportQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as IEncounterDetailsReportQuery)[path])
            ((state.reportProperties as IEncounterDetailsReportQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as IEncounterDetailsReportQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as IEncounterDetailsReportQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as IEncounterDetailsReportQuery)[path] as string[]) = [...currentIds, id];
            }
    },

    setEncounterSummaryProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IEncounterSummaryReportQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IEncounterSummaryReportQuery)[path] as string | number | undefined) = value;
    },
    toggleEncounterSummaryId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IEncounterSummaryReportQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as IEncounterSummaryReportQuery)[path])
            ((state.reportProperties as IEncounterSummaryReportQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as IEncounterSummaryReportQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as IEncounterSummaryReportQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as IEncounterSummaryReportQuery)[path] as string[]) = [...currentIds, id];
            }
    },
    setEncounterStatusProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IEncounterStatusReportQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IEncounterStatusReportQuery)[path] as string | number | undefined) = value;
    },
    toggleEncounterStatusId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IEncounterStatusReportQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as IEncounterStatusReportQuery)[path])
            ((state.reportProperties as IEncounterStatusReportQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as IEncounterStatusReportQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as IEncounterStatusReportQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as IEncounterStatusReportQuery)[path] as string[]) = [...currentIds, id];
            }
    },
};
