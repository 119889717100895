import { PrimaryButton, Stack } from '@fluentui/react';
import IWorkListView from 'api/models/worklist-view.model';
import UserDisplayName from 'components/UserDisplayName';
import { useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ProviderDropdown } from 'pages/components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVisitSummaryBillingProviderSaving } from 'state/slices/admin-huddle/admin-huddle.selectors';
import { updateEncounterProviderByProviderIdType } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import { selectWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import BannerInfoDetailItem from './VisitSummaryEncounterDate';

type VisitSummaryUpdateProviderProps = {
    readOnly?: boolean;
    canEdit?: boolean;
    label: string;
    providerIdType: 'supervisingProviderId' | 'treatingProviderId' | 'billingProviderId' | 'attestingProviderId';
};

export default function VisitSummaryUpdateProvider({ readOnly, label, providerIdType, canEdit }: VisitSummaryUpdateProviderProps) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const selectedView = useSelector(selectWorkListEditItem<IWorkListView>);
    const savingStatus = useSelector(selectVisitSummaryBillingProviderSaving);
    const isSaving = savingStatus === LoadingStatus.Pending;

    const [provider, setProvider] = useState<string | undefined>();

    useEffect(() => {
        if (selectedView && !provider) {
            setProvider(selectedView[providerIdType]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedView]);

    if (readOnly || !canEdit) return <BannerInfoDetailItem label={label} text={<UserDisplayName userId={provider} />} />;

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <ProviderDropdown
                label={label}
                onChange={(ev, option) => {
                    if (option) setProvider(option.key as string);
                }}
                disabled={isSaving}
                selectedKey={provider}
                hideRDAs
            />
            <Stack.Item>
                <PrimaryButton
                    onClick={() => {
                        if (selectedView?.encounterId && selectedView.patientId && provider)
                            dispatch(
                                updateEncounterProviderByProviderIdType({
                                    tenantId,
                                    patientId: selectedView.patientId,
                                    encounterId: selectedView?.encounterId,
                                    providerIdType,
                                    providerId: provider,
                                }),
                            );
                    }}
                    disabled={isSaving || !provider || (selectedView && provider === selectedView[providerIdType])}
                    text={isSaving ? 'Saving...' : `Save ${label}`}
                />
            </Stack.Item>
        </Stack>
    );
}
