import { ChartProcedureDiagnosis } from 'api/models/chart.model';
import { EncounterStatus, IPatientEncounter, IPatientEncounterWithAppointment } from 'api/models/encounter.model';
import { IProcedureDiagnosis } from 'api/models/procedure.model';
import { flatten, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import {
    selectAccountData,
    accountHasGlobalPermissions,
    selectTenantClaimsAndUserRoles,
    selectAccount,
    selectIsAccountGlobalAdmin,
} from '../account.slice';
import { chartProceduresData } from '../charting/procedures/procedures.selectors';
import { selectTenantDiagnoses } from '../tenant';
import { selectProviders } from '../tenant/providers.slice';
import { EncounterState } from './encounter.state';
import IServiceUserAccount from 'api/models/account.model';
import { DentalPermissions, GlobalPermissions } from 'hooks/store/usePermissions';
import { TaskType } from 'state/task-management/taskManagement.actions';
import { selectProviderAttestationFeatureFlag } from '../tenant/feature-flags.slice';

export const encounterState = (state: RootState): EncounterState => state.encounter;
export const selectPatientEncounterAppointment = createSelector(
    encounterState,
    (encounter) => encounter.patientEncounterAppointment,
);

export const selectPatientEncounterPrescriptions = createSelector(encounterState, (encounter) =>
    encounter.patientEncounterPrescriptions.filter((p) => !p.isDeleted),
);

export const selectPatientEncounter = createSelector(encounterState, (encounter) => encounter.patientEncounter);
export const selectPatientEncounterTasks = createSelector(encounterState, (encounter) => encounter.patientEncounterTasks ?? []);
export const selectPatientEncounterAmendTask = createSelector(selectPatientEncounterTasks, (tasks) =>
    tasks.find((t) => t.type === TaskType.Amendment),
);
export const selectCanAmendPatientEncounter = createSelector(
    selectPatientEncounterAmendTask,
    selectAccount,
    (task, accountState) => {
        if (!task) return false;
        return task.references?.providerId === accountState.data?.identity?.id;
    },
);
export const selectPatientEncounterLoading = createSelector(encounterState, (encounter) => encounter.loadingPatientEncounter);
export const selectPatientEncounterSaving = createSelector(encounterState, (encounter) => encounter.savingPatientEncounter);
export const selectLoadingIncompletePatientEncounters = createSelector(
    encounterState,
    (encounter) => encounter.loadingIncompletePatientEncounters,
);

export const chartProcedureDiagnosesByCurrentEncounter = createSelector(
    [chartProceduresData, selectPatientEncounter, selectTenantDiagnoses],
    (procedures, patientEncounter, diagnosesData) => {
        if (!patientEncounter) return [];

        const encounterProceduresDx = flatten(
            procedures
                .filter((p) => p.encounterId === patientEncounter.id && (p.diagnosisCodes?.length ?? 0) > 0)
                .map((p) => p.diagnosisCodes)
                .flat() as ChartProcedureDiagnosis[],
        );

        const uniqDiagIds = uniqBy(encounterProceduresDx, (item) => item.id);

        const procedureDiagnoses: IProcedureDiagnosis[] = uniqDiagIds.map((dx, index) => {
            const code = diagnosesData[dx.id]?.code ? (diagnosesData[dx.id]?.code as string) : '';
            return {
                code,
                mode: index === 0 ? 'auto-apply' : 'no-auto-apply',
            };
        });

        return procedureDiagnoses;
    },
);

export const selectIncompleteEncounters = (state: RootState): IPatientEncounterWithAppointment[] | undefined =>
    state.encounter.patientIncompleteEncounters;

//Returns true when the user has permission.
export function userHasPermissionForEncounterAccess(
    encounter?: IPatientEncounter,
    account?: IServiceUserAccount,
    tenantIdUserRoles?: string[],
) {
    if (!encounter || !account) return true;
    const encounterStatus = encounter?.status;

    if (
        encounterStatus === EncounterStatus.CorrectionsNeeded &&
        (accountHasGlobalPermissions(account, [GlobalPermissions.GlobalAdmin]) ||
            tenantIdUserRoles?.includes(DentalPermissions.Coder))
    )
        return true;

    if (
        encounterStatus === EncounterStatus.CorrectionsNeeded ||
        encounterStatus === EncounterStatus.CorrectionsCompleted ||
        encounterStatus === EncounterStatus.ReBillOnHold ||
        encounterStatus === EncounterStatus.Billed
    )
        return false;

    if (encounterStatus === EncounterStatus.CorrectionAmend) {
        const userId = account?.identity.id;
        return encounter?.attestingProviderId === userId || encounter?.treatingProviderId === userId;
    }
    return true;
}

export const selectIncompleteEncountersWithPermission = createSelector(
    [selectIncompleteEncounters, selectAccountData],
    (data, account) => {
        return (data ?? []).filter((d) => userHasPermissionForEncounterAccess(d.encounter, account));
    },
);

export const selectHasPermissionToAccessCurrentEncounter = createSelector(
    selectPatientEncounter,
    selectAccountData,
    selectTenantClaimsAndUserRoles,
    (patientEncounter, userAccount, tenantUserRoles) => {
        return userHasPermissionForEncounterAccess(patientEncounter, userAccount, tenantUserRoles);
    },
);

export const selectIsTreatingProviderOnEncounter = createSelector(
    selectProviders,
    selectAccountData,
    selectPatientEncounter,
    (providers, account, encounter) => {
        const identityId = account?.identity.id;

        if (identityId && providers.data) {
            return encounter?.treatingProviderId === identityId || encounter?.supervisingProviderId === identityId;
        } else {
            return false;
        }
    },
);

/**Determines if the current user, who is a provider, should need to confirm the attest encounter/note action.*/
export const selectShouldConfirmAttestEncounter = createSelector(
    selectIsTreatingProviderOnEncounter,
    selectProviderAttestationFeatureFlag,
    (isTreatingProviderOnEncounter, providerAttestationFeatureFlag) => {
        return !isTreatingProviderOnEncounter && providerAttestationFeatureFlag;
    },
);

export const selectIsTreatingProvider = createSelector(
    [selectProviders, selectAccountData, selectIsTreatingProviderOnEncounter, selectProviderAttestationFeatureFlag],
    (providers, account, isTreatingProviderOnEncounter, providerAttestationFeatureFlag) => {
        const identityId = account?.identity.id;
        if (identityId && providers.data) {
            const providerMatchesEncounter = providerAttestationFeatureFlag ? true : isTreatingProviderOnEncounter;
            const provider = providers.data[identityId];
            if (provider && provider.isTreatingProvider && providerMatchesEncounter) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
);

export const selectIsTreatingProviderResident = createSelector(
    [selectProviders, selectAccountData, selectIsTreatingProviderOnEncounter, selectProviderAttestationFeatureFlag],
    (providers, account, isTreatingProviderOnEncounter, providerAttestationFeatureFlag) => {
        const identityId = account?.identity.id;
        if (identityId && providers.data) {
            const providerMatchesEncounter = providerAttestationFeatureFlag ? true : isTreatingProviderOnEncounter;
            const provider = providers.data[identityId];
            if (provider && provider.isTreatingProvider && provider.isResident && providerMatchesEncounter) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
);

export const selectIsAttestingHygienist = createSelector(
    [selectProviders, selectAccountData, selectIsTreatingProviderOnEncounter, selectProviderAttestationFeatureFlag],
    (providers, account, isTreatingProviderOnEncounter, providerAttestationFeatureFlag) => {
        const identityId = account?.identity.id;
        if (identityId && providers.data) {
            const providerMatchesEncounter = providerAttestationFeatureFlag ? true : isTreatingProviderOnEncounter;
            const provider = providers.data[identityId];
            if (provider && provider.isAttestingHygienist && providerMatchesEncounter) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
);
